import React from "react";
import Lottie from "lottie-react-web";
import Animation from "../assets/lottyAnimation/home.json";
import AnimationMobile from "../assets/lottyAnimation/homeMobile.json";

const HomeAnimation = () => {
  return (
    <div className="w-full">
      <div className="flex home-bg-image hidden md:block lottie-animation-cursor">
        <Lottie
          options={{
            animationData: Animation,
            loop: true,
            rendererSettings: {},
          }}
        />
      </div>
      <div className="home-bg-image md:hidden">
        <Lottie
          options={{
            animationData: AnimationMobile,
            loop: true,
            rendererSettings: {},
          }}
        />
      </div>
    </div>
  );
};

export default HomeAnimation;
