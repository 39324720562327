import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import LazyImage from "../components/common/LazyImage";
import SwasthyaSuraksha from "../assets/homepage/swasthya-suraksha-Know-More.png";
import healthyWay from "../assets/homepage/healthyway-Know-More.png";
import healthEdge from "../assets/homepage/healthEdge-Know-More.png";
import SwasthyaSurakshaTab from "../assets/homepage/swasthya-suraksha-Know-More-Tab.png";
import healthyWayTab from "../assets/homepage/healthyway-Know-More-Tab.png";
import healthEdgeTab from "../assets/homepage/healthEdge-Know-More-Tab.png";
import SwasthyaSurakshaMobile from "../assets/homepage/swasthya-suraksha-Know-More-Mobile.png";
import healthyWayMobile from "../assets/homepage/healthyway-Know-More-Mobile.png";
import healthEdgeMobile from "../assets/homepage/healthEdge-Know-More-Mobile.png";
import doctorConsulatation from "../assets/homepage/doctor-consulatation.svg";
import cashBenefit from "../assets/homepage/cashBenefit.svg";
import accidentalInsurance from "../assets/homepage/accidentalInsurance.svg";
import nutritionistConsulatation from "../assets/homepage/nutritionist-consulatation.svg";
import diagnosticTest from "../assets/homepage/diagnostic-test.svg";
import fitnessSession from "../assets/homepage/fitness-session.svg";
import ambulanceCall from "../assets/homepage/ambulance-call.svg";
import mentalWellness from "../assets/homepage/mental-wellness.svg";
import CommonForm from "../components/common/CommonForm";

const plansData = {
  "swasthya-suraksha": {
    name: "Swasthya Suraksha",
    discountPrice: "999/-",
    totalPrice: "1,999",
    title: "Your first step to comprehensive health and financial protection",
    image: SwasthyaSuraksha,
    imageTab: SwasthyaSurakshaTab,
    imageMobile: SwasthyaSurakshaMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        description:
          "Tele doctor consultations with MBBS/MD doctors, Covers family of 4, 15+ languages, 24/7, 365 days audio/video consultations",
      },
      {
        subTitle: "Hospital cash benefit",
        image: cashBenefit,
        description:
          "Hospital allowance benefit for hospitalisation of more than 24 hrs, ₹500/- per day (for regular hospitalisation) for 30 days/year, ₹1000/- per day (for ICU hospitalisation) for 30 days/year	Covers, primary member",
      },
      {
        subTitle: "Personal accidental insurance",
        image: accidentalInsurance,
        description:
          "Personal accident insurance of ₹1,00,000/- (sum insured), Covers primary member, Funeral allowance of ₹5000/- upon accidental death",
      },
    ],
  },
  healthyway: {
    name: "Healthyway",
    discountPrice: "1,999/-",
    totalPrice: "3,999",
    title: "Supplement your health insurance with diagnostic care",
    image: healthyWay,
    imageTab: healthyWayTab,
    imageMobile: healthyWayMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        description:
          "10 tele doctor consultations with MBBS/MD doctors, Covers family of 4, 15+ languages, 24/7, 365 days audio/video consultations",
      },
      {
        subTitle: "Nutrition consultation",
        image: nutritionistConsulatation,
        description:
          "4 Consultations with certified nutritionists, Covers primary and secondary members, Customised diet plans tailored to your needs, Regular follow-ups to monitor your progress",
      },
      {
        subTitle: "Diagnostic test",
        image: diagnosticTest,
        description:
          "Health Checkup (64 test), Free Home sample collection  - 1 Vouchers1 Voucher available for 64 parameters diagnostic test, Can be availed once per year, Report within 24 hrs, At-home sample collection",
      },
    ],
  },
  healthedge: {
    name: "HealthEdge",
    discountPrice: "29,999/-",
    totalPrice: "5,9999",
    title: "Holistic health coverage with extensive support services",
    image: healthEdge,
    imageTab: healthEdgeTab,
    imageMobile: healthEdgeMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        description:
          "Tele doctor consultations with MBBS/MD doctors, Covers family of 4, 15+ languages, 24/7, 365 days audio/video consultations.",
      },
      {
        subTitle: "Nutritionist consultation",
        image: nutritionistConsulatation,
        description:
          "4 Consultations with certified nutritionists, Covers primary and secondary members, Customised diet plans tailored to your needs, Regular follow-ups to monitor your progress.",
      },
      {
        subTitle: "Fitness subscription",
        image: fitnessSession,
        description:
          "12 sessions/month,	Available for primary member, Exore 10+ exciting workout forms at 1200+ fitness centres, Enjoy the freedom to workout at your preferred locations",
      },
      {
        subTitle: "Diagnostic test",
        image: diagnosticTest,
        description:
          "1 Voucher available for 64 parameters diagnostic test, Can be availed once per year, Reports within 24 hrs, At-home sample collection.",
      },
      {
        subTitle: "Ambulance on call",
        image: ambulanceCall,
        description:
          "Convenient and reliable transportation for scheduled hospital visits, Available for primary member,	Can be availed once per quarter, Round-the-clock access of equipped ambulances within 2 hours.",
      },
      {
        subTitle: "Mental wellness",
        image: mentalWellness,
        description:
          "Ensure your mental wellness through accessible, compassionate support, Available for primary member, Can be availed once per quarter, Instant chat and video sessions in 10+ languages.",
      },
    ],
  },
};

const KnowMorePlan = () => {
  const { plan } = useParams();
  const planData = plansData[plan.toLowerCase()];
  const formRef = useRef(null);

  const handleBookNow = () => {
    setTimeout(() => {
      if (formRef.current) {
        const offset = 120;
        const elementPosition =
          formRef.current.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  if (!planData) {
    return <div>Plan not found</div>;
  }

  return (
    <div className="home-wrapper flex flex-col items-center">
      <div className="w-full md:max-w-[1248px] mb-[48px] lg:my-[120px]">
        <div className="bgc-transparent know-more-plan-wrapper lg:p-10 rounded-[40px]">
          <div className="hidden lg:block">
            <div className="grid grid-cols-2 gap-14 mb-20">
              <div className="flex flex-col justify-center px-5">
                <div className="heading1 mb-2">{planData.name}</div>
                <div className="font-[400] leading-9 text-[24px] mb-6">
                  {planData.title}
                </div>
                <div className="flex items-center mb-10">
                  <span className="heading2 pr-2">
                    ₹{planData.discountPrice}
                  </span>
                  <span className="para line-through">
                    {planData.totalPrice}
                  </span>
                </div>
                <button
                  class="my-button h-10 w-[178px]"
                  onClick={() => handleBookNow()}
                >
                  Get a call back
                </button>
              </div>

              <div className="relative">
                <div className="validity-text para w-[185px] font-[400]">
                  Validity 365 days
                </div>
                <LazyImage
                  className="lazy-img rounded-3xl"
                  src={planData.image}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="lg:hidden">
            <div className="grid grid-cols-1 gap-6 md:gap-10 md:mb-20 lg:mb-6">
              <div className="relative">
                <div className="validity-text para font-[400]">
                  Validity 365 days
                </div>
                <LazyImage
                  className="lazy-img hidden md:block lg:hidden xl:hidden 2xl:hidden"
                  src={planData.imageTab}
                  alt=""
                />
                <LazyImage
                  className="lazy-img md:hidden sm:block"
                  src={planData.imageMobile}
                  alt=""
                />
              </div>

              <div className="px-5 md:mb-0 mb-10">
                <div className="heading1 mb-3">{planData.name}</div>
                <div className="md:text-[18px] para mb-3">{planData.title}</div>
                <div className="mb-3 flex items-center">
                  <span className="heading2 pr-2">
                    ₹{planData.discountPrice}
                  </span>
                  <span className="para line-through">
                    {planData.totalPrice}
                  </span>
                </div>
                <button
                  class="my-button h-10 w-[178px]"
                  onClick={() => handleBookNow()}
                >
                  Get a call back
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
            {planData.benefits.map(({ image, subTitle, description }) => (
              <div className="know-more-plan-card rounded-lg text-center p-5">
                <div className="flex justify-center mb-6">
                  <img src={image} alt="" className="h-20 w-20" />
                </div>
                <div>
                  <h2 className="heading3 mb-2 know-more-subTitle">
                    {subTitle}
                  </h2>
                  <p className="para leading-6 font-[400]">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          ref={formRef}
          className="know-more-plan-wrapper plan-wrapper py-10 px-5 md:p-10 md:rounded-[40px] mt-[48px] md:mt-[120px]"
        >
          <p className="heading2 text-center md:text-left mb-2">
            Get a call back
          </p>
          <p className="para text-center md:text-left">
            Know more about {planData.name} plan?
          </p>
          <CommonForm subject={`ourplan - ${planData.name}`} title="Submit" />
        </div>
      </div>
    </div>
  );
};

export default KnowMorePlan;
