import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="home-wrapper flex flex-col justify-center items-center text-justify px-5 lg:px-0">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px]">
        <div>
          <h1 className="heading1">Terms & Conditions</h1>
        </div>
        <div className="mt-10 para">
          <p>
            The following terms and conditions constitute an agreement between
            you and Hospido Private Limited (“aiqahealth,” “we,” or “us”), the
            operator of https://aiqahealth.com/ (the “site”). this agreement
            governs your use of the site, both as an unregistered visitor and as
            a registered user. by using the site, and/or by registering with us,
            you signify that you agree to these terms of use, including that you
            consent to the information practices disclosed in our privacy
            policy, which is incorporated herein by reference. please note that
            we offer the site “as is” and without warranties. all information we
            collect on the site is subject to our privacy policy. by using the
            site, you consent to all actions taken by us with respect to the
            information, provided by you directly and indirectly, in compliance
            with the privacy policy.{" "}
            <p>
              {" "}
              The services offered and provided through this website are in
              accordance with applicable laws, professional standards including
              but not limited to the telemedicine guidelines issued by the board
              of governors of the medical council of India dated 25.03.2020 and
              as amended from time to time.
            </p>{" "}
            This site is offered and available to users who are 18 years of age
            or older. by using this site, you represent and warrant that you are
            of legal age to form a binding contract with aiqahealth and meet all
            of the foregoing eligibility requirements. if you do not meet all of
            these requirements, you must not access or use the site.
          </p>
        </div>
        <div className="mt-10">
          <h1 className="heading2 mb-2">Medical information</h1>
          <div>
            <p className="mb-2 para">
              The information provided by aiqahealth is for educational purposes
              only and not intended to be a substitute for professional medical
              advice, diagnosis, or treatment. you are advised to seek
              professional diagnosis and treatment for any medical condition. do
              not disregard, avoid or delay obtaining medical advice from a
              healthcare professional because of something that you may have
              read on the site.
            </p>
            <p className="mb-2 para">
              Your use of information provided on the site is at your own risk.
              nothing stated or posted on the site or available through any of
              the provided services are intended to be, and must not be taken to
              be, the practice of medicine or the provision of medical care. you
              are strongly advised to discuss information obtained from this
              website with your healthcare provider before connecting with
              clinical trial contacts on our site.
            </p>
            <p className="mb-2 para">
              We do not endorse or recommend the engagement of any particular
              medical practitioner or service provider listed on this website,
              and you shall engage with such medical practitioner or service
              provider at your own risk and volition.
            </p>
          </div>
        </div>
        <div className="mt-10">
          <h1 className="heading2">Intellectual property</h1>
          <div className="mt-2">
            <p className="mb-2">
              Unless otherwise stated, we or our licensors own the intellectual
              property rights in the website and material on the website.
              Subject to the license below, all these intellectual property
              rights are reserved. You may view, download for caching purposes
              only, and print pages from the website for your own personal use,
              subject to the restrictions set out below and elsewhere in these
              terms of use. You must not:
            </p>
            <ul className="para mt-2 list-disc pl-7">
              <li>
                Republish material from this website (including republication on
                another website);
              </li>
              <li>Sell, rent or sub-license material from the website;</li>
              <li>Show any material from the website in public;</li>
              <li>
                Reproduce, duplicate, copy or otherwise exploit material on our
                website for a commercial purpose;
              </li>
              <li>Edit or otherwise modify any material on the website;</li>
              <li>
                Redistribute material from this website (except for content
                specifically and expressly made available for redistribution);
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="mb-2 heading2">Restrictions of use</h1>
          <div>
            <p className="mb-2 para">
              You must not use our website in any way that causes, or may cause,
              damage to the website or impairment of the availability or
              accessibility of the website; or in any way which is unlawful,
              illegal, fraudulent or harmful, or in connection with any
              unlawful, illegal, fraudulent or harmful purpose or activity.
            </p>
            <p className="mb-2 para">
              You must not conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to our
              website without our express written consent. you must not use our
              website for any purposes related to marketing without our express
              written consent.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="mt-2 heading2">Restriction of access</h1>
          <div className="para">
            <p className="mb-2">
              Access to certain areas of our website is restricted. we reserve
              the right to restrict access to other areas of our website, or
              indeed our whole website, at our discretion. we may disable your
              user id and password in our sole discretion without notice or
              explanation.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="mt-2 heading2">Limitation of liability</h1>
          <div className="para">
            <p className="mb-2">
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to this
              website, whether express or implied, and the use of this website
              (including, without limitation, any warranties implied by law of
              satisfactory quality, fitness for purpose and/or the use of
              reasonable care and skill)
            </p>
            <p className="mb-2">
              We provide diagnostic services through third party service
              providers. We shall not be liable or responsible for any
              deficiency in service and / or any inaccuracies, errors, or
              omissions in the test report provided by our diagnostic services
              partner channel.
            </p>
            <p className="mb-2">
              We provide the site and the services “as is”, “with all faults”
              and “as available.” we make no express or implied warranties or
              guarantees about the services. to the maximum extent permitted by
              law, we hereby disclaim all such warranties, including all
              statutory warranties, with respect to the services and the site,
              including without limitation any warranties that the services are
              merchantable, of satisfactory quality, accurate, fit for a
              particular purpose or need, or non-infringing. we do not guarantee
              that the results that may be obtained from the use of the services
              and/or the website will be effective, reliable, or accurate or
              will meet your requirements.
            </p>
            <p className="mb-4">
              Our liability to you in relation to the use of our website or
              under or in connection with these terms of use, whether in
              contract, tort (including negligence) or otherwise, will be
              limited as follows:
            </p>
            <ul className="mb-2 list-disc pl-7">
              <li>
                To the extent that the website and the information and services
                on the website are provided free-of-charge, we will not be
                liable for any loss or damage of any nature;
              </li>
              <li>
                We will not be liable for any consequential, indirect or special
                loss or damage;
              </li>
              <li>
                We will not be liable for any loss of profit, income, revenue,
                anticipated savings, contracts, business, goodwill, reputation,
                data, or information;
              </li>
              <li>
                We will not be liable for any loss or damage arising out of any
                event or events beyond our reasonable control;
              </li>
              <li>
                Our maximum liability in relation to any event or series of
                related events will be limited to INR 5,000.
              </li>
            </ul>

            <p className="mb-2">
              You accept that we have an interest in limiting the personal
              liability of our officers and employees, having regard to that
              interest, you accept that we are a company incorporated under the
              companies act, 1956 (and validly subsisting under the provisions
              of companies act, 2013) a limited liability entity, and agree that
              you will not bring any claim personally against individual
              officers or employees in respect of any losses you suffer in
              connection with the website or the services provide therein/
              hereunder or these terms of use. this will not, of course, limit
              or exclude the liability of the company itself for the acts and
              omissions of our officers and employees.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Indemnity</h1>
          <div className="para">
            <p className="mb-2">
              You hereby indemnify us, our licensors and service providers, and
              ours and their respective officers, directors, employees,
              contractors, agents, licensors, suppliers, successors and assigns
              (“indemnified parties”) and undertake to keep the indemnified
              parties indemnified against any losses, damages, costs,
              liabilities and expenses (including without limitation legal
              expenses and other expenses or charges and any other amounts paid
              by the indemnified parties to a third party in settlement of a
              claim or dispute on the advice of the indemnified parties’ legal
              advisers) incurred or suffered by the indemnified parties arising
              out of any breach by you of any provision of these terms of use or
              your use of the site, or arising out of any claim or judgment that
              you have breached any provision of these terms of use.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Breach of these terms & conditions</h1>
          <div className="para">
            <p className="mb-2">
              Without prejudice to our other rights under these terms of use, if
              you breach these terms of use in any way, or if we reasonably
              suspect that you have breached these terms of use in any way, we
              may:
            </p>
            <ul className="mb-2 list-disc pl-7">
              <li>Send you one or more formal warnings;</li>
              <li>Temporarily suspend your access to the website;</li>
              <li>Permanently prohibit you from accessing the website;</li>
              <li>
                Block computers using your IP address from accessing the
                website;
              </li>
              <li>
                contact your internet services provider and request that they
                block your access to the website;
              </li>
              <li>
                bring court proceedings against you for breach of contract or
                otherwise;
              </li>
              <li>suspend and/or delete your account with the website;</li>
            </ul>
            <p className="mb-2">
              Where we suspend or prohibit or block your access to our website
              or a part of our website, you must not take any action to
              circumvent such suspension or prohibition or blocking (including
              without limitation creating and/or using a different account).
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Fees & Payments</h1>
          <div className="para">
            <p className="mb-2">
              The website is a service platform, using third-party payment
              gateways, appointed at its sole discretion, to process the
              transactions made on the website. the transactions and payments
              made on the website shall be as per the payment and refund
              mechanism of the third-party payment gateway service providers and
              the company ’doctor on call private limited shall not be liable
              for any failed monetary payment transactions. in the event of any
              failed transaction, the amount (if any debited from a user’s
              account) will be refunded to the user’s account, as per the
              corresponding third-party payment gateway’s payment and refund
              policy. notwithstanding anything contained herein, while making
              the payment at this website, using the services of such third
              party payment gateway, the user confirms to understand the payment
              and refund policy of third party payment gateway and no monetary
              and/ or non-monetary liability of the company otherwise, and
              confirms and agree to be bound and governed by the terms and
              conditions of use and other applicable policies and payment and
              refund mechanism of third party payment gateway and the company.
              the company doctor on call private limited under no circumstances
              shall be liable for any loss caused to any user in connection with
              the payment and usage of such third-party payment gateway. no
              cancellation or refund shall be applicable in case the user does
              not wish to continue with the company services after the booking
              has been made, except where the company is unable to provide the
              booked/paid services to the user, for any reasons including the
              reasons beyond the control of the company. under such case, the
              company may initiate a refund, in full or partial depending upon
              the circumstances and discretion of the company, within seven
              working days.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Third party websites</h1>
          <div className="para">
            <p className="mb-2">
              Our site includes hyperlinks to other websites owned and operated
              by third parties. these links are not recommendations. we have no
              control over the contents of third-party websites, and we accept
              no responsibility for them or for any loss or damage that may
              arise from your use of them.
            </p>
            <p className="mb-2">
              You may link to our homepage, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval or endorsement on
              our part without our express written consent.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Logos, marks, designs etc.</h1>
          <div className="para">
            <p className="mb-2">
              aiqahealth [aiqahealth.org] and our logo and designs are owned by
              us. we give no permission for the use of these logos, marks, and
              designs, unless in express and in writing permitted by us, and
              such use may constitute an infringement of our rights.
            </p>
            <p className="mb-2">
              The other registered and unregistered trademarks or service marks
              on our website are the property of their respective owners. unless
              stated otherwise, we do not endorse and are not affiliated with
              any of the holders of any such rights and as such we cannot grant
              any license to exercise such rights.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Amendments to the terms and conditions</h1>
          <div className="para">
            <p className="mb-2">
              We may revise these terms of use from time-to-time. revised terms
              of use will apply to the use of our website from the date of the
              publication of the revised terms of use on our website. please
              check this page regularly to ensure you are familiar with the
              current version. your continued use of the site implies acceptance
              of any revised terms of use.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Assignment & sub-contracting</h1>
          <div className="para">
            <p className="mb-2">
              We may sub-contract, assign, delegate or otherwise transfer our
              rights, remedies and obligations under these terms of use without
              notifying you or obtaining your consent.
            </p>
            <p className="mb-2">
              You may not sub-contract, assign, delegate or otherwise transfer
              your rights, remedies and obligations under these terms of use,
              without our express written consent.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Force majeure</h1>
          <div className="para">
            <p className="mb-2">
              In the event of the occurrence of an event beyond the reasonable
              control and contemplation of the parties that prevents aiqahealth
              to provide the services offered on this website or perform any
              such obligation under these terms and conditions, aiqahealth shall
              not be held liable for any loss arising from such breach.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Waiver & Severability</h1>
          <div className="para">
            <p className="mb-2">
              If any and all provision(s) of these terms of use is determined by
              any court or other competent authority to be unlawful and/or
              unenforceable, the other provisions and related terms will
              continue in effect. if any unlawful and/or unenforceable provision
              would be lawful or enforceable if part of it were deleted, that
              part will be deemed to be deleted, and the rest of the provision
              will continue in effect.
            </p>
            <p className="mb-2">
              Any temporary or one or other time waiver of any provision or term
              of the policy by us as set forth in these terms of use shall not
              be deemed a further or continuing waiver of such term or a waiver
              of any other term, and any failure by us to assert a right or
              provision under these terms of use shall not constitute a waiver
              of such right or provision.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Third party benefits</h1>
          <div className="para">
            <p className="mb-2">
              Except as otherwise set forth in these terms of use, these terms
              of use are for the benefit of you and us and are not intended to
              benefit any third party or be enforceable by any third party. the
              exercise of our and your rights in relation to these terms of use
              is not subject to the consent of any third party.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">
            Services offered are neither an insurance nor a health insurance
          </h1>
          <div className="para">
            <p className="mb-2">
              Services offered by aiqahealth are not covered under the ambit of
              insurance or health insurance business or policies
            </p>
            <p className="mb-2">
              “Services offered by Hospido Private Limited are not in any way
              type of or substitute of insurance or health insurance policies.
              the services provided to the aiqahealth members are through
              aiqahealth in house aiqahealthtors or aiqahealth employees or
              through aiqahealth hospitals only. aiqahealth also provides an
              access to aiqahealth virtual health care services. the benefits
              (including discounted benefits) provided to the aiqahealth members
              are not applicable for any insurance claim.”
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Complete agreement</h1>
          <div className="para">
            <p className="mb-2">
              These terms of use, together with our privacy policy, cookie
              policy, and other policies mentioned in these terms of use or
              anytime published on the website of the aiqahealth, constitute the
              entire agreement between you and us in relation to your use of our
              website, and supersede all previous agreements, understandings,
              representations and warranties (both oral and written) in respect
              of your use of this website.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Compliance with law</h1>
          <div className="para">
            <p className="mb-2">
              These terms of use and the services provided by aiqahealth are
              subject to and in compliance with the applicable laws of the
              republic of india including but not limited to the telemedicine
              guidelines issued by the board of governors of the medical council
              of india dated 25.03.2020 and as amended from time to time.
            </p>
            <p className="mb-2">
              The website may be accessible worldwide, however, we make no
              representation that materials on the website are appropriate or
              available for use in locations outside india or that the website
              complies with the laws that are applicable in any jurisdiction
              that is outside India. accessing the website from territories
              where the content of the website and/or the provision of the
              services is deemed illegal is prohibited. users accessing the
              website from locations outside India do so at their own risk and
              are responsible for compliance with local laws that are applicable
              to them. any offer for any service, and/or information made in
              connection with the platform is void where prohibited.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Governing law and jurisdiction</h1>
          <div className="para">
            <p className="mb-2">
              These terms of use are governed by the laws of the republic of
              India and subject to the sole and exclusive jurisdiction of the
              courts of New Delhi, Haryana.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
