import React from "react";

const Label = ({ label = "enter", required = false }) => {
  return (
    <label className="para pl-2 para text-[12px]">
      {label} {required && <span className="text-primary-500 para">*</span>}
    </label>
  );
};

export default Label;
