import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const CustomNavLink = ({ to, exact, mobileNav = false, ...props }) => {
  const location = useLocation();
  const isActive = () => {
    const currentPath = location.pathname + location.hash;
    if (exact) {
      return currentPath === to;
    }
    return currentPath.startsWith(to);
  };

  return (
    <NavLink
      to={to}
      {...props}
      className={
        isActive() && mobileNav
          ? mobileNav
          : isActive()
          ? "text-primary-500"
          : "md:hover:text-primary-500" 
      }
    />
  );
};

export default CustomNavLink;
