import React from "react";
import Input from "./Input";
import Label from "./Label";
import { validateMobileNumber } from "../../services/constant";

const MobileNumberInput = ({
  label = "",
  required,
  value,
  onChange,
  ...inputProps
}) => {
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [error, setError] = React.useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    if (!validateMobileNumber(value)) {
      setError("Invalid phone number");
    } else {
      setError("");
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label label={label} required={required} />
      <Input
        {...inputProps}
        id="mobile-number"
        value={value}
        onChange={handleChange}
        placeholder="Enter your number"
        maxLength={10}
      />
      {error && <span className="text-[12px] text-primary-500">{error}</span>}
    </div>
  );
};

export default MobileNumberInput;
