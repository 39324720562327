import React, { useState } from "react";
import Label from "./Label";
import Input from "./Input";
import { validateEmail } from "../../services/constant";

const EmailInput = ({
  label = "Email",
  name,
  required,
  value,
  onChange,
  placeholder = "Enter your email",
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!validateEmail(value)) {
      setError("Invalid email address");
    } else {
      setError("");
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label label={label} required={required} />
      <Input
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {error && email && (
        <span className="text-[12px] text-primary-500 pl-2">{error}</span>
      )}
    </div>
  );
};

export default EmailInput;
