import React, { useEffect, useState } from "react";
import ourJourneyBanner from "../../assets/about/ourJourneyBanner.png";
import ourJourneyBannerTab from "../../assets/about/ourJourneyBannerTab.png";
import ourJourneyBannerMobile from "../../assets/about/ourJourneyBannerMobile.png";
import LazyImage from "../common/LazyImage";

const OurJourney = () => {
  const [imageSrc, setImageSrc] = useState(ourJourneyBanner);

  const handleResize = () => {
    const width = window.innerWidth;

    if (width >= 1024) {
      // lg and above
      setImageSrc(ourJourneyBanner);
    } else if (width >= 768 && width < 1024) {
      // md breakpoint (tablets)
      setImageSrc(ourJourneyBannerTab);
    } else {
      // sm breakpoint (mobile)
      setImageSrc(ourJourneyBannerMobile);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full">
      <LazyImage className="lazy-img w-full" src={imageSrc} alt="" />
    </div>
  );
};

export default OurJourney;
