import React from "react";
import { useInView } from "react-intersection-observer";

const LazyImage = ({ src, alt, placeholder, ...props }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the image is in view
  });

  return (
    <img
      ref={ref}
      src={src}
      alt={alt}
      {...props}
      style={{ transition: "opacity 0.5s", opacity: inView ? 1 : 0 }}
    />
  );
};

export default LazyImage;
