import React from "react";
import CommonForm from "../components/common/CommonForm";
import LazyImage from "../components/common/LazyImage";
import aiqaTransform from "../assets/aiqa-transform/aiqa-transform.png";
import aiqaTransformTab from "../assets/aiqa-transform/aiqa-transform-tab.png";
import aiqaTransformMobile from "../assets/aiqa-transform/aiqa-transform-mobile.png";
import nutritionistConsulatation from "../assets/aiqa-transform/nutritionist-consulatation.svg";
import digitalTrackingGoals from "../assets/aiqa-transform/digital-tracking-goals.svg";
import rabitCoach from "../assets/aiqa-transform/rabit-coach.svg";
import heart from "../assets/aiqa-transform/heart.svg";
import doctorConsulatation from "../assets/aiqa-transform/doctor-consulatation.svg";
import goalbasedWorkouts from "../assets/aiqa-transform/goal-based-workouts.svg";

const AiqaTransform = () => {
  const getData = [
    {
      title: "Nutritionist consults",
      description:
        "no deprivation, calorie counting or crash diets here! fortnightly consult with nutritionist.",
      image: nutritionistConsulatation,
    },
    {
      title: "Digital tracking of your goals",
      description:
        "no more planning required! a customized plan is shared with you on your app daily.",
      image: digitalTrackingGoals,
    },
    {
      title: "Habit coach",
      description:
        "daily check-ins and more! aiqa transform team will conduct regular counseling session.",
      image: rabitCoach,
    },
    {
      title: "77 parameter health check-up",
      description:
        "focus on holistic health! we believe in scientific method to weight loss, your subscription includes a comprehensive health checkup.",
      image: heart,
    },
    {
      title: "Doctor consults",
      description:
        "learn about you body and take charge! the health check-up is followed by a doctor consult to help you understand your health report better.",
      image: doctorConsulatation,
    },
    {
      title: "Goal based workouts and meditation sessions",
      description:
        "permanent results! your subscription includes free access to cultpass LIVE membership powered by cult.fit",
      image: goalbasedWorkouts,
    },
  ];

  return (
    <div className="home-wrapper flex justify-center">
      <div className="w-full md:max-w-[1248px] mb-[48px] lg:my-[120px]">
        <div className="bgc-transparent know-more-plan-wrapper lg:p-10 rounded-[40px]">
          <div className="hidden lg:block">
            <div className="grid grid-cols-2 gap-14 mb-20">
              <div className="flex flex-col justify-center px-5">
                <div className="heading1 mb-2">aiqa transform</div>
                <div className="font-[400] leading-9 text-[24px] mb-6">
                  your scientific approach to weight loss
                </div>
                <div className="flex items-center mb-10">
                  <span className="heading2 pr-2">₹5999/-</span>
                  <span className="para line-through">₹7499</span>
                </div>
                <div className="bg-[#FBE7E8] px-3 py-[6px] rounded-xl w-52">
                  plan validity: 3 months
                </div>
              </div>

              <div className="relative">
                <LazyImage
                  className="lazy-img rounded-3xl"
                  src={aiqaTransform}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="hidden md:block lg:hidden xl:hidden 2xl:hidden">
            <div className="grid gap-6 md:gap-10 mb-20">
              <div className="relative">
                <div className="validity-text heading3 font-[400]">
                  plan validity: 3 months
                </div>
                <LazyImage className="lazy-img" src={aiqaTransformTab} alt="" />
              </div>
              <div className="px-5">
                <div className="heading1 mb-3">aiqa transform</div>
                <div className="mb-3">
                  <span className="heading2 pr-2">₹5999/-</span>
                  <span className="para line-through">₹7499</span>
                </div>
                <div className="font-[400] leading-9 text-[24px]">
                  your scientific approach to weight loss
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden">
            <div className="grid grid-cols-1 gap-6 md:gap-10">
              <div className="relative">
                <div className="validity-text para font-[400]">
                  plan validity: 3 months
                </div>
                <LazyImage
                  className="lazy-img"
                  src={aiqaTransformMobile}
                  alt=""
                />
              </div>

              <div className="px-5 md:mb-0 mb-10">
                <div className="mb-3">
                  <div className="heading1 mb-3">aiqa transform</div>
                  <div className="para mb-3">
                    your scientific approach to weight loss
                  </div>
                  <div className="mb-3">
                    <span className="heading2 pr-2">₹5999/-</span>
                    <span className="para line-through">₹7499</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
            {getData.map(({ image, title, description }) => (
              <div className="know-more-plan-card rounded-lg text-center p-5">
                <div className="flex justify-center mb-6">
                  <img src={image} alt="" className="h-20 w-20" />
                </div>
                <div>
                  <h2 className="heading3 mb-2 know-more-subTitle">{title}</h2>
                  <p className="para leading-6 font-[400]">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="know-more-plan-wrapper plan-wrapper py-10 px-5 md:p-10 md:rounded-[40px] mt-4 md:mt-[120px]">
          <p className="heading2 text-center md:text-left mb-2">
            Book your free consult
          </p>
          <p className="para text-center md:text-left">
            Know more about aiqa transform?
          </p>
          <CommonForm subject="aiqa transform" title="Book a consultation" />
        </div>
      </div>
    </div>
  );
};

export default AiqaTransform;
