import React from "react";
import HomeCards from "../sections/HomeCards";
import HomeAnimation from "../sections/HomeAnimation";
import OurPlans from "../components/OurPlans";
import WhyAiqaPlan from "../components/WhyAiqaPlan";
import HowAiqahealthWorks from "../components/HowAiqahealthWorks";
import DownloadAppFrom from "../components/DownloadAppFrom";
import WhatWeOffer from "../components/WhatWeOffer";
import OurServiceProvider from "../components/OurServiceProvider";
import OurPresence from "../components/OurPresence";
import InNews from "../components/InNews";
import Comments from "../components/Comments";
import FAQ from "../components/FAQ";

const Home = () => {
  return (
    <div className="home-wrapper">
      <div className="w-full flex flex-col items-center">
        <HomeAnimation />
      </div>
      <div className="flex flex-col items-center px-5 lg:px-0">
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]flex flex-col items-center">
          <HomeCards />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <OurPlans />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] why-aiqa-plan-wrapper">
          <WhyAiqaPlan />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <HowAiqahealthWorks />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <DownloadAppFrom />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] mb-10">
          <WhatWeOffer />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <OurServiceProvider title="Service providers" />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] px-5 lg:px-0">
          <OurPresence />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] px-5 lg:px-0">
          <InNews />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] mb-10 px-5 lg:px-0">
          <Comments />
        </div>
        <div className="w-full md:max-w-[1248px] my-[48px] md:my-[120px]">
          <FAQ type="home" />
        </div>
      </div>
    </div>
  );
};

export default Home;
