import React from "react";
import Deepika from "../assets/homepage/deepika.png";
import Amit from "../assets/homepage/amit.png";
import Lalita from "../assets/homepage/lalita.png";
import DeepikaMobile from "../assets/homepage/deepika-mobile.png";
import AmitMobile from "../assets/homepage/amit-mobile.png";
import LalitaMobile from "../assets/homepage/lalita-mobile.png";
import CustomCarousel from "./common/CustomCarousel";

const Comments = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const data = [
    {
      name: "Deepika",
      comment:
        "aiqa health's fitness subscription transformed my routine. Access to 1200+ centers and diverse workouts like yoga, pilates, and strength training helped me stay active and achieve my fitness and weight loss goals.",
      img: Deepika,
      imgMobile: DeepikaMobile,
    },
    {
      name: "Amit",
      comment:
        "Thanks to aiqa health's nutrition consultation service, I achieved remarkable weight loss and significantly improved my overall well-being—all within my budget.",
      img: Amit,
      imgMobile: AmitMobile,
    },
    {
      name: "Lalita",
      comment:
        "Living in a remote village without nearby medical services, aiqa's Health Centre service was a lifesaver. I was able to consult with a doctor, receive a diagnosis, and obtain necessary medications promptly, all at no cost.",
      img: Lalita,
      imgMobile: LalitaMobile,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center w-full flex-col">
      <p className="heading1 text-center mb-10">What our customers say</p>

      <CustomCarousel settings={settings}>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <div className="customer-comment pt-[75px] md:pt-[90px] m-2 relative">
                <div className="card-wrapper min-h-[350px] md:min-h-[340px]">
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
                    <img
                      src={item?.img}
                      alt=""
                      height="180px"
                      width="180px"
                      className="rounded-full"
                    />
                  </div>
                  <div className="px-5">
                    <div className="pb-[40px] pt-[110px] md:pt-[120px] md:pb-[77px]">
                      <div className="heading3 text-[20px] text-center mb-2 text-primary-500">
                        {item.name}
                      </div>
                      <div
                        className="text-center para"
                        style={{ lineHeight: "24px" }}
                      >
                        <span style={{ color: "#db2228" }}>“ </span>
                        {item.comment}
                        <span style={{ color: "#db2228" }}> ”</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </CustomCarousel>
    </div>
  );
};

export default Comments;
