import React from "react";
import heart from "../../assets/about/heart.svg";
import eClinic from "../../assets/about/e-clinic.svg";
import pin from "../../assets/about/pin.svg";
import hand from "../../assets/about/hand-shake.svg";

const OurImpact = () => {
  const data = [
    { img: heart, title: "80 Lakh+", description: "Subscriptions" },
    { img: eClinic, title: "250+", description: "health Centers" },
    { img: pin, title: "5000+", description: "Pincodes" },
    { img: hand, title: "100+", description: "Partners" },
  ];

  return (
    <div className="text-center">
      <h1 className="heading1 mb-6 md:mb-10">Our impact</h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-4">
        {data.map((item, index) => {
          let orderClass = "";
          if (index === 1) {
            orderClass = "order-3";
          } else if (index === 2) {
            orderClass = "order-4";
          } else if (index === 3) {
            orderClass = "order-2";
          }

          return (
            <div
              key={index}
              className={`p-3 md:p-10 lg:w-[300px] bg-white rounded-xl flex flex-col items-center justify-center ${orderClass} md:order-none`}
            >
              <img
                alt=""
                className="h-[60px] w-[60px] md:h-20 md:w-20 mb-6"
                src={item.img}
              />
              <h2 className="heading3 mb-1 md:mb-4">{item.title}</h2>
              <p className="para">{item.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurImpact;
