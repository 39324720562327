import React from "react";
import CustomCarousel from "../common/CustomCarousel";
import CheckIcon from "../../assets/common/check-all.svg";

const ForCustomer = ({ title }) => {
  const data = [
    {
      name: "Financial business",
      benefits: [
        "Online doctor consultation",
        "Hospital cash benefit",
        "Personal accidental insurance",
        "Credit life insurance",
        "Ayushman bharat nerollment",
        "Discounts on medicines",
        "Ambulance on call",
        "Digital health records",
      ],
      imgClass: "customer-plan-img-wrapper-1",
    },
    {
      name: "Fitness business",
      benefits: [
        "Doctor consultation",
        "Nutritionist consultation",
        "Personal accidental insurance",
        "Digital health records",
        "Discounts on medicines",
        "Diagnostic test",
      ],
      imgClass: "customer-plan-img-wrapper-2",
    },
    {
      name: "Travel business",
      benefits: [
        "Doctor consultation",
        "Nutritionist consultation",
        "Digital health records",
        "Discounts on medicines",
        "Diagnostic test",
      ],
      imgClass: "customer-plan-img-wrapper-3",
    },
    {
      name: "Nutrition business",
      benefits: [
        "Doctor consultation",
        "Diagnostic test",
        "Fitness subscription",
        "Digital health records",
        "Discounts on medicines",
      ],
      imgClass: "customer-plan-img-wrapper-4",
    },
    {
      name: "Diagnostics business",
      benefits: [
        "Doctor consultation",
        "Nutritionist consultation",
        "Fitness subscription",
        "Mental wellness subscription",
        "Digital health records",
        "Discounts on medicines",
      ],
      imgClass: "customer-plan-img-wrapper-5",
    },
    {
      name: "Wellness business",
      benefits: [
        "Doctor consultation",
        "Nutrition consultation",
        "Diagnostic test",
        "Fitness subscription",
        "Digital health records",
        "Discounts on medicines",
      ],
      imgClass: "customer-plan-img-wrapper-6",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.07,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.65,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="flex justify-center w-full flex-col mb-20 hidden md:block">
        <p className="heading1 text-center mb-2">
          What's in it for your customer
        </p>
        <p className="para font-[500] text-center">
          Enhance your business with our curated plans
        </p>

        <CustomCarousel settings={settings}>
          {data.map((item, index) => {
            return (
              <div className="mt-10 ml-0 lg:ml-5">
                <div className="flex flex-wrap justify-center gap-5 md:gap-6">
                  <div>
                    <div className="customer-plan-card-wrapper p-5" key={index}>
                      <div>
                        <div className={item.imgClass}>
                          <div className="h-1/2"></div>
                          <div className="plan-name-section h-1/2 rounded-b-[10px]">
                            <p className="plan-name">{item.name}</p>
                          </div>
                        </div>
                        <div className="mt-5 benefits-wrapper">
                          <ul className="flex flex-col gap-2">
                            {item.benefits.map((benefit, i) => (
                              <li className="flex gap-1" key={i}>
                                <img
                                  src={CheckIcon}
                                  alt=""
                                  height="18px"
                                  width="18px"
                                />
                                <span className="para">{benefit}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CustomCarousel>
      </div>

      <div className="flex justify-center w-full flex-col md:hidden">
        <p className="heading1 text-center mb-2">
          What's in it for your customer
        </p>
        <p className="para text-center font-[500]">
          Enhance your Business with our Curated Plan
        </p>

        {data.map((item, index) => {
          return (
            <div className=" mt-6 md:mt-10">
              <div className="flex flex-wrap justify-center gap-5 md:gap-6">
                <div>
                  <div className="customer-plan-card-wrapper p-5" key={index}>
                    <div>
                      <div className={item.imgClass}>
                        <div className="h-1/2"></div>
                        <div className="plan-name-section h-1/2 rounded-b-[10px]">
                          <p className="plan-name">{item.name}</p>
                        </div>
                      </div>
                      <div className="mt-5 benefits-wrapper">
                        <ul className="flex flex-col gap-2">
                          {item.benefits.map((benefit, i) => (
                            <li className="flex gap-1" key={i}>
                              <img
                                src={CheckIcon}
                                alt=""
                                height="18px"
                                width="18px"
                              />
                              <span className="para">{benefit}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ForCustomer;
