import React from "react";
import sunilSachdeva from "../../assets/about/sunil-sachdeva-sir.png";
import karanArora from "../../assets/about/karan-chopra-sir.png";
import SukritiSachdeva from "../../assets/about/sukriti-sachdeva-man.png";
import rajatArora from "../../assets/about/rajat-arora-sir.png";

const OurTeam = () => {
  const data = [
    { img: sunilSachdeva, name: "Sunil Sachdeva", title: "Director" },
    { img: karanArora, name: "Karan Chopra", title: "Director" },
    { img: SukritiSachdeva, name: "Sukriti Sachdeva", title: "Director" },
    { img: rajatArora, name: "Rajat Arora", title: "CBO" },
  ];
  return (
    <div className="w-full">
      <div className="text-center">
        <p className="heading1 mb-2">Our team</p>
        <p className="para font-[500] text-center mb-10">
          Meet the visionaries and leaders revolutionising and redefining
          healthcare at aiqa health.
        </p>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-12">
        {data?.map((item, index) => {
          return (
            <div
              className="card rounded-3xl lg:w-[276px] p-5 md:p-10 our-team-card-wrapper flex items-center justify-center flex-col relative"
              key={index}
            >
              <img
                alt=""
                className="h-20 w-20 md:h-48 md:w-48"
                src={item.img}
              />
              <p className="heading3 mt-2 md:mt-4 md:leading-[28.8px] mb-[4px] font-heading3-sm">
                {item.name}
              </p>
              <p className="para font-para-sm">{item.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurTeam;
