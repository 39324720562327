import React, {  forwardRef } from "react";
import DatePicker from "react-datepicker";
import Label from "./Label";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you import the CSS for the date picker
import moment from "moment";

const DateInput = ({
  label = "Date",
  required,
  placeholder = "DD/MM/YYYY",
  maxDate,
  onChange,
  minDate,
  value
}) => {
 

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <input
      value={value}
      onClick={onClick}
      ref={ref}
      className="h-12 rounded-lg p-2 outline-none w-full"
      placeholder="DD/MM/YYYY"
    />
  ));  

  return (
    <div className="flex flex-col gap-2">
      <Label label={label} required={required} />
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={value ?moment(value,'DD-MM-YYYY').toDate():null}
        onChange={(date) => onChange(date)}
        placeholderText={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={<CustomDateInput />}
      />
    </div>
  );
};

export default DateInput;
