import React from "react";

const Input = ({
  placeholder = "please enter",
  type = "text",
  ...inputProps
}) => {
  return (
    <input
      {...inputProps}
      placeholder={placeholder}
      className="h-12 rounded-lg p-2 outline-none w-full"
      type={type}
    />
  );
};

export default Input;
