import React from "react";
import Ambitious from "../../assets/about/Ambitious.png";
import Integrated from "../../assets/about/Integrated.png";
import Questioning from "../../assets/about/Questioning.png";
import Accountable from "../../assets/about/Accountable.png";

const OurValues = () => {
  const data = [
    {
      img: Ambitious,
      title: "Ambitious",
      description:
        "Get uncomfortable, strive for continuous improvement and growth with a relentless hunger to be better every day.",
    },
    {
      img: Integrated,
      title: "Integrated",
      description:
        "Embrace teamwork, foster respect, care for each other and nurture a culture of ‘we’ rather than ‘me’.",
    },
    {
      img: Questioning,
      title: "Questioning",
      description:
        "Be inherently curious. Consistently evaluate actions and decisions - is it right for the customer? Is it right for the company?",
    },
    {
      img: Accountable,
      title: "Accountable",
      description:
        "Demonstrate commitment to delivering high-quality work and taking full ownership of responsibilities.",
    },
  ];
  return (
    <div>
      <div className="text-center">
        <p className="heading1 mb-2">Our values</p>
        <p className="para font-[500] mb-6 md:mb-10">
          At aiqa Health, our values are the cornerstone of our identity
        </p>
      </div>
      <div className="flex justify-center flex-wrap gap-4 md:gap-10">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="w-[37.7rem] md:h-[31rem] rounded-3xl our-values-card p-5"
            >
              <img alt="" src={item.img} className="w-full" />
              <p className="mb-2">
                <span className="text-primary-500 heading1 leading-[75px] font-heading1-sm">
                  {item.title[0]}
                </span>
                <span className="heading2 font-heading2-sm">{item.title.substring(1)}</span>
              </p>
              <p className="para leading-[24px] pb-3 md:pb-5">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurValues;
