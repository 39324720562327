import React from "react";
import mail from "../assets/contact_us/fluent_mail-20-filled-red.svg";

const HowToReach = () => {
  return (
    <div className="home-wrapper flex justify-center px-5 lg:px-0">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px] flex flex-col">
        <div className="flex flex-wrap justify-center gap-10">
          <div className="w-full text-start pl-2">
            <h1 className="heading1">How to Reach Us</h1>
          </div>
          <div className="w-full flex flex-wrap lg:flex-nowrap gap-5 justify-between">
            <div className="card w-full lg:w-[40%] p-6 lg:p-10 rounded-3xl">
              <p className="heading2">Level 1</p>
              <p className="para mt-[10px]">you can write to us </p>
              <div className="flex items-center gap-4 mt-10 md:mt-20">
                <span className="bg-white p-2 rounded-full">
                  <img src={mail} alt="" />
                </span>
                <span >care@aiqahealth.com</span>
              </div>
            </div>
            <div
              animateIn="bounceInRight"
              className="card w-full lg:w-[55%] p-6 md:p-10 rounded-3xl"
            >
              <p className="heading2">Level 2</p>
              <p className="para mt-2">
                if for any reason, your grievance has not been resolved to your
                satisfaction, please write to
              </p>
              <div className="mt-10 para">
                Nodal officer customer grievance
                <p className="mt-2">
                  doctor on call private limited, 5th floor, sas tower, sector –
                  38, gurugram, 122001
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToReach;
