import React from "react";

const AiqaLogoFooter = () => {
  return (
    <div className="text-white">
      <span style={{ fontWeight: 600, fontSize: "29.23px" }}>aiqa</span>
      <span style={{ fontWeight: 300, fontSize: "29.23px" }}>health</span>
    </div>
  );
};

export default AiqaLogoFooter;
