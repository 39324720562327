import React from "react";
import connection from "../../assets/become_reseller/connection.svg";
import increment from "../../assets/become_reseller/increment.svg";
import payment from "../../assets/become_reseller/payment.svg";

const ForYou = () => {
  return (
    <div className="text-center">
      <p className="heading1 mb-6 md:mb-10">What's in it for you</p>
      <section className="grid grid-cols-3 gap-2 md:gap-4">
        <div className="whatInIt-section-card py-3 px-2 md:py-10 md:px-5">
          <div className="flex justify-center items-center mb-6">
            <img
              alt=""
              className="h-11 w-11 md:h-20 md:w-20"
              src={connection}
            />
          </div>
          <p className="para font-[300] md:font-[500] text-[12px] md:text-[16px]">Integrate seamlessly with your current platform</p>
        </div>
        <div className="whatInIt-section-card py-3 px-2 md:py-10 md:px-5">
          <div className="flex justify-center items-center mb-6">
            <img alt="" className="h-11 w-11 md:h-20 md:w-20" src={increment} />
          </div>
          <p className="para font-[300] md:font-[500] text-[12px] lg:text-[16px]">Enhance value proposition of your products</p>
        </div>
        <div className="whatInIt-section-card py-3 px-2 md:py-10 md:px-5">
          <div className="flex justify-center items-center mb-6">
            <img alt="" className="h-11 w-11 md:h-20 md:w-20" src={payment} />
          </div>
          <p className="para font-[300] md:font-[500] text-[12px] md:text-[16px]">Increase revenue with cross sell</p>
        </div>
      </section>
    </div>
  );
};

export default ForYou;
