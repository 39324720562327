import React from "react";

const Grievances = () => {
  return (
    <div className="home-wrapper flex flex-col justify-center items-center text-justify px-5 lg:px-0">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px]">
        <div>
          <h1 className="heading1 text-left">Customer grievances redressal policy</h1>
        </div>
        <div className="mt-10">
          <h2 className="heading2 mb-2">Privacy policy</h2>
          <p className="mb-2">
            During the present times when healthcare services is the key for
            sustainable life and people are really concerned for their
            well-being and health, provision for providing efficient healthcare
            delivery is the utmost important instrument for achieving the
            confidence and support of customer, and with such support, excel in
            customer service for sustained development in the business and its
            objectives of growth and milestones achievement.
          </p>
          <p className="mb-2">
            Customer (including the user of the website www.doctoroncall.org)
            complaints are part of the business life of any corporate entity. as
            being in a service sector and a service provider, customer
            satisfaction is the paramount objective for any healthcare business.
            doctor on call private limited (doc) believes that providing prompt
            and efficient service is extremely important not only to build
            confidence among new customers, but also to triumph over the
            existing customers.
          </p>
          <p className="mb-2">
            Doc aims at minimizing instances of customer complaints and
            grievances through proper, prompt and efficient service delivery,
            follow up review mechanism, and ensuring expeditiously effective
            redressal of complaints and grievances, if any The review mechanism
            helps in identifying the shortcomings in services delivered/ made
            available for the customers of doc or otherwise.
          </p>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">
            Key principles of doc while service delivery:
          </h1>
          <div className="para">
            <ul className="mb-2 list-disc pl-7">
              <li>Customers are to be treated courteously at all the times.</li>
              <li>
                Complaints raised by customers are to be dealt in all fairness
                and in time bound manner
              </li>
              <li>
                Customers are to be fully supported to escalate their complaints
                / grievances to the company and their rights to avail a suitable
                alternative remedy if they are not fully satisfied with the
                response is also available
              </li>
              <li>
                Staff of DOC will always work in good faith and without
                prejudice to the interests of the customers.
              </li>
              <li>
                To always know, acknowledge and understand that customer
                satisfaction and his well-being and good health is the key and
                primary concern for DOC
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">Awareness of grievance redressal system</h1>
          <div>
            <p className="mb-2">
              at doc, the customer’s, including website user’s experience is
              what we deliberate and focus on. this is why doc listens and take
              time to know and understand the need of its users and take their
              concerns seriously. going skin-deep to analyse, understand and
              scrutinize, how one would feel if stepped into the shoes of
              customer/ consumer to avail the services of doc from the inception
              the one’s step-in to doc till the time they are done, helps doc
              evolve and enhance its service delivery. if a customer, including
              website user, has any time found the experience with doc simply
              smooth and incredible, we know we are doing something worthy.
              however, when a customer/ user is concerned or has any grievance
              from doc or from the services provided by the doc, doc shall do
              everything it can do to fix it and make it right.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <h1 className="heading2 mb-2">
            Sensitizing staff on handling complaints
          </h1>
          <div className="mb-2">
            <p className="mb-2">
              our staff undergoes regular training to ensure that consumers
              queries and grievances are handled properly and efficiently. they
              are encouraged to work in a manner which helps’ the company in
              building the consumer trust and confidence. this reflects in both
              the operations as well as the customer communications. any
              grievance received, minor or major, through the customer or
              website user, are thoroughly analyzed and worked upon immediately
              in a way which aims at efficient and effective redressal and
              resolution of such grievance/ complaint from the root or its very
              inception.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grievances;
