let enrollmentUATBaseUrl;

if (process.env.REACT_APP_ENVIRONMENT === "UAT") {
  enrollmentUATBaseUrl =
    "https://enrollment-uat.aiqahealth.com/enrollment-service";
} else {
  enrollmentUATBaseUrl =
    "https://enrollment-prod.aiqahealth.com/enrollment-service";
}

export const jobApplyUrl = `${enrollmentUATBaseUrl}/jobApplication/upload_job_application`;
