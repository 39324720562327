import React from "react";
import CustomCarousel from "./common/CustomCarousel";
import cultFit from "../assets/homepage/Cult-fit.png";
import zenzo from "../assets/homepage/Zenzo.png";
import u4rad from "../assets/homepage/U4rad.png";
import neuberg from "../assets/homepage/Neuberg.png";
import medulance from "../assets/homepage/Medulance.png";
import thyrocare from "../assets/homepage/Thyrocare.png";
import lissun from "../assets/homepage/Lissun.png";
import healthians from "../assets/homepage/Healthians.png";
import acko from "../assets/homepage/Acko.png";
import ageasFederalLifeInsurance from "../assets/homepage/Ageas-federal-life-insurance.png";
import careHealthInsurance from "../assets/homepage/Care-health-insurance.png";
import hdfcErgo from "../assets/homepage/Hdfc-ergo.png";
import liberty from "../assets/homepage/liberty.png";
import pramericaLifeInsurance from "../assets/homepage/Pramerica-life-insurance.png";
import kotak from "../assets/homepage/Kotak.png";

const OurServiceProvider = ({ title }) => {
  const data = [
    cultFit,
    zenzo,
    u4rad,
    neuberg,
    medulance,
    thyrocare,
    lissun,
    healthians,
    acko,
    ageasFederalLifeInsurance,
    careHealthInsurance,
    hdfcErgo,
    liberty,
    pramericaLifeInsurance,
    kotak,
  ];

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className="card-wrapper text-center p-5 md:p-10 flex justify-center flex-col">
      <p className="heading1 mb-6 md:mb-10">{title}</p>
      <div className="w-full flex justify-center">
        <div className="w-full text-center">
          <CustomCarousel settings={settings}>
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-center md:w-full">
                    <img
                      className="h-[70px] md:h-20 lg:h-24"
                      alt="img"
                      key={index}
                      src={item}
                    />
                  </div>
                </div>
              );
            })}
          </CustomCarousel>
        </div>
      </div>
    </div>
  );
};

export default OurServiceProvider;
