import React from "react";
import rohit from "../../assets/become_reseller/rohit.svg";
import ganesh from "../../assets/become_reseller/ganesh.svg";
import vikas from "../../assets/become_reseller/vikas.svg";
import CustomCarousel from "../common/CustomCarousel";

const Testimonials = () => {
  const data = [
    {
      img: rohit,
      name: "Rohit Sahani",
      title: "Founder Swastik Finance",
      comment:
        "We  are  thrilled  to  partner  with  aiqahealth  in  their  mission  to grow healthcare delivery in rural India. Their commitment to serve customers in the most crucial hours has been a game changer for us. Together, we aim to make a lasting impact across lives to contribute to the socio-economic progress in rural areas.",
    },
    {
      img: ganesh,
      name: "Ganesh Modak",
      title: "Managing Director, Gramin Shakti",
      comment:
        "The asociation with aiqahealth has been very fulfilling. We have grown together since our partnership, and i take pride in working with an NPS 100 company.",
    },
    {
      img: vikas,
      name: "Vikas Singh",
      title: "CEO Sugmya Finance Pvt Ltd",
      comment:
        "aiqahealth's affordable and easy to use health services have  benefitted  our  customers  massively.  Their commitment to excellence and customer satisfaction is unparalleled.Sugmya Finance has greatly benefited from partnership with aiqahealth. I recommend them to anyone in need of quality healthcare solutions.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center w-full flex-col">
      <p className="heading1 text-center mb-10">Testimonials</p>

      <CustomCarousel settings={settings}>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <div className="customer-comment pt-[90px] m-2 relative">
                <div className="card-wrapper min-h-[430px] md:min-h-[360px]">
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
                    <img
                      src={item?.img}
                      alt=""
                      height="180px"
                      width="180px"
                      className="rounded-full"
                    />
                  </div>
                  <div className="px-5">
                    <div className="pt-[100px] md:pt-[120px] pb-[30px]">
                      <div className="heading3 text-[20px] text-center mb-1 text-primary-500">
                        {item.name}
                      </div>
                      <div className="text-[14px] font-[400] text-[#000000] leading-5 text-center mb-2">
                        {item.title}
                      </div>
                      <div
                        className="text-center para"
                        style={{ lineHeight: "24px" }}
                      >
                        <span style={{ color: "#db2228" }}>“ </span>
                        {item.comment}
                        <span style={{ color: "#db2228" }}> ”</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </CustomCarousel>
    </div>
  );
};

export default Testimonials;
