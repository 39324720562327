import React from "react";
import playStore from "../assets/homepage/play-store.png";
import appStore from "../assets/homepage/app-store.png";

const DownloadAppFrom = () => {
  return (
    <div
      id="download"
      className="download-app grid grid-cols-1 lg:grid-cols-2 px-6 md:px-10 py-5 md:py-12"
    >
      <div className="heading1 justify-left text-center lg:text-left mb-6 lg:mb-0">
        Download app from
      </div>
      <div className="flex items-center justify-between md:justify-around">
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.aiqahealth"
            target="_blank"
          >
            <img src={playStore} alt="img" className="h-9 md:h-11" />
          </a>
        </div>
        <div>
          <a
            href="https://apps.apple.com/in/app/aiqahealth/id1588240478"
            target="_blank"
          >
            <img src={appStore} alt="img" className="h-9 md:h-11" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppFrom;
