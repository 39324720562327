import React from "react";
import CheckAllIcon from "../../assets/common/check-all.svg";
import useViewport from "../../hooks/getMobileWidth";
import { Link } from "react-router-dom";

const PlanCard = () => {
  const { width } = useViewport();
  return (
    <section className="flex flex-wrap justify-center gap-5 md:gap-6">
      <div>
        <div className="plan-card-wrapper p-5">
          <div>
            <div className="plan-img-wrapper-1 mb-5">
              <div className="h-1/2"></div>
              <div className="plan-name-section h-1/2 rounded-b-[10px]">
                <p className="plan-name">Swasthya Suraksha</p>
                <span className="additional-text">
                  Your first step to comprehensive health and financial
                  protection
                </span>
              </div>
            </div>
            <div className="benefits-wrapper">
              <ul className="flex flex-col gap-2">
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Doctor consultation</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Hospital cash benefit</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Personal accidental insurance</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="plan-button-wrapper">
            <Link
              to="/our-plan/swasthya-suraksha"
              className="know-more-button font-[500]"
            >
              Know more
            </Link>
          </div>
        </div>
      </div>
      <div delay={width < 720 ? 500 : 200}>
        <div className="plan-card-wrapper p-5">
          <div>
            <div className="plan-img-wrapper-2 mb-5">
              <div className="h-1/2 ">
                <div className="plan-tag-wrapper">
                  <p className="plan-tag">
                     <span className="para">Popular</span>
                  </p>
                </div>
              </div>
              <div className="plan-name-section h-1/2 rounded-b-[10px]">
                <p className="plan-name2">Healthyway</p>
                <span className="additional-text2">
                  Supplement your health insurance with diagnostic care
                </span>
              </div>
            </div>
            <div className="benefits-wrapper">
              <ul className="flex flex-col gap-3">
                <li className="flex gap-2">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />{" "}
                  <span className="para">Doctor consultation</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Nutritionist consultation</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">64 parameters diagnostic test</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="plan-button-wrapper">
            <Link
              to="/our-plan/healthyway"
              className="know-more-button font-[500]"
            >
              Know more
            </Link>
          </div>
        </div>
      </div>
      <div delay={width < 720 ? 500 : 300}>
        <div className="plan-card-wrapper p-5">
          <div>
            <div className="plan-img-wrapper-3 mb-5">
              <div className="h-1/2"></div>
              <div className="plan-name-section h-1/2 rounded-b-[10px]">
                <p className="plan-name2">HealthEdge</p>
                <span className="additional-text2">
                  Holistic health coverage with extensive support services
                </span>
              </div>
            </div>
            <div className="benefits-wrapper">
              <ul className="flex flex-col gap-3">
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />{" "}
                  <span className="para">Doctor consultation</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Nutritionist consultation</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span className="para">Fitness subscription</span>
                </li>
                <li className="flex gap-1">
                  <img src={CheckAllIcon} alt="" height="18px" width="18px" />
                  <span>Ambulance on call</span>
                </li>
                <p className="flex gap-1 more-benefits-wrapper">
                  <Link to="/our-plan/healthEdge">
                     <span className="para">+3 More Benefits</span>
                  </Link>
                </p>
              </ul>
            </div>
          </div>

          <div className="plan-button-wrapper">
            <Link
              to="/our-plan/healthEdge"
              className="know-more-button font-[500]"
            >
              Know more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanCard;
