import React, { useRef, useState } from "react";
import uploadImg from "../../assets/about/UploadSimple.svg";
import MobileNumberInput from "../form/MobileNumberInput";
import EmailInput from "../form/EmailInput";
import DateInput from "../form/DateInput";
import Label from "../form/Label";
import moment from "moment";
import { validateEmail, validateName } from "../../services/constant";
import { IoClose } from "react-icons/io5";
import NameInput from "../form/NameInput";
import { jobApplyUrl } from "../../services/apiUrl";
import Swal from "sweetalert2";
import axios from "axios";

const HowToApplyForm = () => {
  const initialState = {
    name: "",
    email: "",
    mobile: "",
    dob: "",
    year: "",
    month: "",
    jobProfile: "",
    resume: "",
  };

  const [data, setData] = useState(initialState);
  const [acknowledge, setAcknowledge] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const checkboxRef = useRef(null);

  const onChangeHandler = (e) => {
    let { value, name, files } = e.target;
    if (name === "year" || name === "month") {
      if (/^\d+$/.test(value) || !value) {
        if ((name === "month" && value > 12) || (name === "year" && value > 60))
          return;
        if (value === "00") value = 0;
        setData({ ...data, [name]: value });
      } else return;
    } else if (name === "resume") {
      const selectedFile = files[0];
      if (selectedFile) {
        const validFileTypes = ["application/pdf", "application/msword"];
        const isValidFileType = validFileTypes.includes(selectedFile.type);
        const isValidFileSize = selectedFile.size <= 4 * 1024 * 1024; // 4 MB

        if (!isValidFileType) {
          setError("Only .doc, .docx, and .pdf files are allowed.");
          setData({ ...data, resume: null });
        } else if (!isValidFileSize) {
          setError("File size must be less than 4 MB.");
          setData({ ...data, resume: null });
        } else {
          setError("");
          setData({ ...data, resume: selectedFile });
        }
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const onDOBChange = (date) => {
    setData({ ...data, dob: moment(date).format("DD-MM-YYYY") });
  };

  const validateData = () => {
    if (!validateName(data.name) || data.name.length < 3) {
      return false;
    } else if (data.email && !validateEmail(data.email)) {
      return false;
    } else if (data.mobile.length < 10 || !/^\d+$/.test(data.mobile)) {
      return false;
    } else if (!data.resume || !data.dob) {
      return false;
    } else if (!acknowledge) {
      return false;
    } else {
      return true;
    }
  };

  const onAcknowledgeChange = (e) => {
    setAcknowledge(e.target.checked);
  };

  const toggleCheckbox = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checkboxRef.current.checked;
      setAcknowledge(checkboxRef.current.checked);
    }
  };

  const onRemoveFile = (e) => {
    e.stopPropagation();
    setData({ ...data, resume: null });
    const fileInput = document.getElementById("dropzone-file");
    fileInput.value = "";
    fileInput.removeEventListener("click", e.preventDefault());
  };

  const onApplyHandler = () => {
    setLoading(true);
    const { name, email, mobile, dob, year, month, jobProfile, resume } = data;

    const formdata = new FormData();
    formdata.append("file", resume);
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("email", email);
    formdata.append("dob", dob);
    formdata.append("jobProfile", jobProfile);
    formdata.append("year", year);
    formdata.append("month", month);

    axios
      .post(jobApplyUrl, formdata)
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: "",
            text: response?.data?.message,
            icon: "success",
            confirmButtonColor: "#db2228",
          });
          setData(initialState);
          setAcknowledge(false);
          setError("");

          const fileInput = document.getElementById("dropzone-file");
          if (fileInput) {
            fileInput.value = "";
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Error in making the request. Please try again.",
          icon: "error",
          confirmButtonColor: "#db2228",
        });
        setError(error?.response?.data?.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="how-to-apply-container md:px-8 md:py-6 p-4 relative">
      <form
        onChange={onChangeHandler}
        className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
      >
        <div>
          <NameInput label="Full name" required value={data.name} />
        </div>
        <div>
          <EmailInput
            label="Email"
            name="email"
            placeholder="Enter your email"
            value={data.email}
          />
        </div>
        <div>
          <MobileNumberInput
            name="mobile"
            label="Phone number"
            required
            placeholder="Enter your number"
            className="p-2 rounded"
            value={data.mobile}
          />
        </div>
        <div>
          <DateInput
            name="dob"
            label="Date Of Birth"
            required
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            minDate={new Date().setFullYear(new Date().getFullYear() - 50)}
            placeholder="dd-MM-yyyy"
            onChange={onDOBChange}
            value={data?.dob}
          />
        </div>
        <div>
          <Label label="Total work experience" />
          <div className="flex justify-around bg-white rounded-lg p-3 h-12 mt-2">
            <input
              name="year"
              maxLength={2}
              className="w-full outline-none"
              placeholder="Years"
              value={data.year}
              type="tel"
            />
            <div className="h-6 border-l border-[#9ca3af] mx-2" />
            <input
              name="month"
              maxLength={2}
              className="w-full outline-none pl-2"
              placeholder="Months"
              value={data.month}
              type="tel"
            />
          </div>
        </div>
        <div>
          <Label label="Select function" />
          <select
            name="jobProfile"
            id="fun"
            className={`shadow-sm bg-white text-gray-900 rounded-lg w-full h-12 outline-none p-2 text-[14px] ${
              !data?.jobProfile && "opacity-60"
            } mt-2`}
            required
            value={data.jobProfile}
          >
            <option value="" className="hidden">
              Select
            </option>
            <option value="accounts">Accounts</option>
            <option value="customer_executive">Customer Executive</option>
            <option value="design">Design</option>
            <option value="doctor_health_executive">
              Doctor/Health Executive
            </option>
            <option value="legal">Legal</option>
            <option value="marketing">Marketing</option>
            <option value="operations">Operations</option>
            <option value="product">Product</option>
            <option value="sales">Sales</option>
            <option value="tech_technology">Tech/Technology</option>
          </select>
        </div>
        <div className="col-span-1 md:col-span-2">
          <Label label="Attach resume" required />
          <div className="flex items-center justify-center w-full mt-2">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer bg-white"
            >
              <div className="flex items-center justify-between pl-2 pr-2 w-full h-12">
                <p className="text-gray-500 dark:text-gray-400">
                  <span
                    className={`text-[#333333] text-[14px] ${
                      !data?.resume?.name && "opacity-60"
                    }`}
                  >
                    {data?.resume ? data.resume?.name : "Upload"}
                  </span>
                </p>
                {data.resume ? (
                  <IoClose className="opacity-60" onClick={onRemoveFile} />
                ) : (
                  <img src={uploadImg} alt="" className="opacity-100" />
                )}
              </div>
              <input
                name="resume"
                id="dropzone-file"
                type="file"
                className="hidden"
                accept=".doc,.pdf"
              />
            </label>
          </div>
          {error && <span className="text-primary-500 para pl-2">{error}</span>}
        </div>
      </form>
      <div className="flex items-center mt-5">
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={acknowledge}
          onChange={onAcknowledgeChange}
          className="custom-checkbox h-5 w-5 border border-red"
        />
        <span
          onClick={toggleCheckbox}
          className="text-left md:text-justify para text-[#666666] font-para-sm cursor-pointer pl-3"
        >
          I acknowledge that all the provided data is accurate.
        </span>
      </div>
      <div className="flex justify-center mt-6 md:mt-10">
        <button
          onClick={onApplyHandler}
          disabled={!validateData() || loading}
          className="my-button h-[40px] w-[165px]"
        >
          {loading ? "Loading..." : "Apply now"}
        </button>
      </div>
    </div>
  );
};

export default HowToApplyForm;
