import React from "react";
import file from "../assets/common/file-download.svg";

const InvestorPortal = () => {
  const data = [
    {
      year: 2023,
      anim: "bounceInLeft",
      delay: 300,
      link: "https://aiqa-doc-legal-objects.s3.ap-south-1.amazonaws.com/legal-objects/Form+MGT-7A+-+Abridged+Annual+Return+-+2023.pdf",
    },
    {
      year: 2022,
      anim: "bounceInUp",
      delay: 600,
      link: "https://aiqa-doc-legal-objects.s3.ap-south-1.amazonaws.com/legal-objects/Form+MGT-7A+-+Abridged+Annual+Return+-+2022.pdf",
    },
    {
      year: 2021,
      anim: "bounceInRight",
      delay: 900,
      link: "https://aiqa-doc-legal-objects.s3.ap-south-1.amazonaws.com/legal-objects/Form+MGT-7A+-+Abridged+Annual+Return+-+2021.pdf",
    },
  ];

  const openPDF = (pdfLink) => {
    window.open(pdfLink, "_blank"); // Open the PDF in a new tab
  };

  return (
    <div className="home-wrapper flex justify-center px-5 lg:px-0">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px]">
        <h1 className="heading1 text-center md:text-start">Investor Portal</h1>
        <div className="mt-10 card p-10 rounded-3xl">
          <p className="heading2 text-center md:text-left mb-10">
            Annual Return
          </p>
          <div className="flex flex-wrap lg:flex-nowrap justify-around gap-10">
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  delay={item.delay}
                  className="flex p-6 bg-primary-100 lg:w-96 rounded-3xl gap-3"
                >
                  <img alt="" src={file} />
                  <div>
                    <p className="para">Annual Return ({item.year})</p>
                    <span
                      className="text-primary-500 para cursor-pointer"
                      onClick={() => openPDF(item.link)}
                    >
                      download
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorPortal;
