import React from "react";
import Lottie from "lottie-react-web";
import animation from "../../assets/lottyAnimation/apiKit.json";
import customerMobileApp from "../../assets/become_reseller/customerMobileApp.png";
import partnersPortal from "../../assets/become_reseller/partnersPortal.png";

const TechPoweredSolutions = () => {
  const data = [
    {
      title: "Customer mobile app",
      subTitle:
        "Easily manage appointments, access medical records, and consult doctors from your smartphone",
      img: customerMobileApp,
    },
    {
      title: "Reseller portal",
      subTitle:
        "A seamless interface for our partners to manage services, monitor performance, and integrate solutions efficiently",
      img: partnersPortal,
    },
    {
      title: "API kit",
      subTitle:
        "Comprehensive API solutions for effortless integration with existing healthcare systems and third-party applications, enhancing overall service delivery",
      isLottie: true,
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center text-center p-5 lg:px-0">
      <h1 className="heading1 mb-2">Human-led tech-powered solutions</h1>
      <p className="para font-[500] mb-10">
        At aiqa, we combine the expertise of our healthcare professionals with
        advanced technology to deliver exceptional services
      </p>
      <div className="flex flex-wrap justify-center gap-6">
        {data.map((item, index) => {
          const isSecondItem = index === 1;
          return (
            <div
              key={index}
              className="card rounded-[40px] grid items-center justify-between grid-cols-1 md:grid-cols-2 p-5 lg:py-[25px] lg:px-[120px] gap-4 md:gap-[90px]"
            >
              <div className={`text-left ${isSecondItem ? "md:order-2" : ""}`}>
                <p className="leading-9 md:leading-9 text-[20px] md:text-[24px] font-[600] mb-1 md:mb-2">
                  {item.title}
                </p>
                <p className="para md:leading-6">{item.subTitle}</p>
              </div>
              <div
                className={`${
                  isSecondItem ? "md:order-1" : ""
                } lottie-animation-cursor`}
              >
                {item.isLottie ? (
                  <Lottie
                    options={{
                      animationData: animation,
                      loop: true,
                      rendererSettings: {},
                    }}
                  />
                ) : (
                  <img src={item.img} alt="" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TechPoweredSolutions;
