import React from "react";
import BecomeAReseller from "../components/becomeAReseller/BecomeAReseller";
import OurImpact from "../components/about_us/OurImpact";
import OurPartner from "../components/OurPartner";
import ForCustomer from "../components/becomeAReseller/ForCustomer";
import ForYou from "../components/becomeAReseller/ForYou";
import TechPoweredSolutions from "../components/becomeAReseller/TechPoweredSolutions";
import Testimonials from "../components/becomeAReseller/Testimonials";

const BecomeReseller = () => {
  return (
    <div className="about-us-container flex flex-col items-center">
      <div className="w-full animation-banner-bg">
        <BecomeAReseller />
      </div>
      <div className="w-full md:max-w-[1248px]">
        <div className="px-5 lg:px-0">
          <div className="mt-[48px] md:mt-[120px]">
            <OurImpact />
          </div>
        </div>
        <div className="mt-[48px] md:mt-[120px]">
          <OurPartner title="Our partners" />
        </div>
        <div className="px-5 lg:px-0">
          <div className="mt-[48px] md:mt-[120px]">
            <ForCustomer />
          </div>
          <div className="mt-[48px] md:mt-[120px]">
            <ForYou />
          </div>
        </div>
        <div className="mt-[48px] md:mt-[120px] tech-solution">
          <TechPoweredSolutions />
        </div>
        <div className="px-5 lg:px-0">
          <div className="mt-[48px] md:mt-[120px] mb-[68px] md:mb-[120px]">
            <Testimonials />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeReseller;
