import React, { useState } from "react";
import Label from "./Label";
import Input from "./Input";
import { validateName } from "../../services/constant";

const NameInput = ({ label = "name", value, required = false, onChange }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const onNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (!validateName(value)) {
      setError("Invalid name");
    } else {
      setError("");
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label label={label} required={required} />
      <Input
        placeholder="Enter your name"
        name="name"
        onChange={onNameChange}
        maxLength={32}
        value={value}
      />
      {error && name && (
        <span className="text-[12px] text-primary-500">{error}</span>
      )}
    </div>
  );
};

export default NameInput;
