import React, { useEffect, useRef, useState } from "react";

const HowAiqahealthWorks = () => {
  const [step, setStep] = useState(1);
  const rightSectionRef = useRef(null);
  const isClickScrollRef = useRef(false);

  const scrollPositions = {
    1: 0,
    2: 500,
    3: 995,
    4: 1485,
  };

  const handleScroll = () => {
    if (rightSectionRef.current && !isClickScrollRef.current) {
      const rightSection = rightSectionRef.current;
      const scrollPosition = rightSection.scrollTop;

      if (scrollPosition < 495) {
        setStep(1);
      } else if (scrollPosition >= 495 && scrollPosition < 992) {
        setStep(2);
      } else if (scrollPosition >= 992 && scrollPosition < 1485) {
        setStep(3);
      } else {
        setStep(4);
      }
    }
  };

  const handleStepClick = (stepNumber) => {
    if (rightSectionRef.current) {
      isClickScrollRef.current = true;

      // Scroll to the corresponding position
      rightSectionRef.current.scrollTo({
        top: scrollPositions[stepNumber],
        behavior: "smooth",
      });

      // Set the step state to match the clicked step
      setStep(stepNumber);

      // Delay allowing scroll to override step state
      setTimeout(() => {
        isClickScrollRef.current = false;
      }, 500);
    }
  };

  useEffect(() => {
    const rightSection = rightSectionRef.current;

    if (rightSection) {
      rightSection.addEventListener("scroll", handleScroll);

      // Clean up on unmount
      return () => {
        rightSection.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div>
      <section id="workSection" className="hidden lg:block relative">
        <h1 className="text-center heading1 mb-2">How aiqa health works</h1>
        <p className="text-center para font-[500]">
          Your journey to wellness is made easy with these simple steps
        </p>
        <div className="workSectionWrapper mt-10">
          <div className="flex justify-around">
            <div className="lg:w-[50%] md:w-[45%] h-[400px] flex items-center">
              <div className="leftContent">
                <div className="stepWrapper">
                  <ul>
                    <li
                      onClick={() => handleStepClick(1)}
                      className="cursor-pointer"
                    >
                      <span
                        className={
                          "stepWrapperSpan " + (step === 1 ? "active" : "")
                        }
                      ></span>
                      <span
                        className={`heading3 ${
                          step === 1 ? "active font-semibold" : "font-normal"
                        }`}
                      >
                        Choose a plan
                      </span>
                    </li>
                    <li
                      onClick={() => handleStepClick(2)}
                      className="cursor-pointer"
                    >
                      <span
                        className={
                          "stepWrapperSpan " + (step === 2 ? "active" : "")
                        }
                      ></span>
                      <span
                        className={`heading3 ${
                          step === 2 ? "active font-semibold" : "font-normal"
                        }`}
                      >
                        Book your service
                      </span>
                    </li>
                    <li
                      onClick={() => handleStepClick(3)}
                      className="cursor-pointer"
                    >
                      <span
                        className={
                          "stepWrapperSpan " + (step === 3 ? "active" : "")
                        }
                      ></span>
                      <span
                        className={`heading3 ${
                          step === 3 ? "active font-semibold" : "font-normal"
                        }`}
                      >
                        Receive comprehensive care
                      </span>
                    </li>
                    <li
                      onClick={() => handleStepClick(4)}
                      className="cursor-pointer"
                    >
                      <span
                        className={
                          "stepWrapperSpan " + (step === 4 ? "active" : "")
                        }
                      ></span>
                      <span
                        className={`heading3 ${
                          step === 4 ? "active font-semibold" : "font-normal"
                        }`}
                      >
                        Access digital health records
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              ref={rightSectionRef}
              className="lg:w-[50%] md:w-[55%] flex justify-center"
              style={{
                overflowY: "scroll",
                height: "400px",
                scrollbarWidth: "none",
              }}
            >
              <div className="rightContent step1Image fade-in-image flex flex-col justify-start item">
                <img
                  src={require("../assets/homepage/choose-plan.png")}
                  alt=""
                />
                <img
                  id="imgScroll"
                  src={require("../assets/homepage/select-service.png")}
                  alt=""
                />
                <img
                  id="imgScroll"
                  src={require("../assets/homepage/check-report.png")}
                  alt=""
                />
                <img
                  id="imgScroll"
                  className="img-4"
                  src={require("../assets/homepage/speak-to-doc.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- WORK SECTION FOR MOBILE STARTS --> */}
      <section id="workSectionMobile" className="lg:hidden">
        <div className="workSectionWrapperMobile">
          <div className="container">
            <div className="workHeader">
              <h1 className="heading1 text-center">How aiqahealth works</h1>
            </div>
            <div className="flex relative">
              <div className="w-[6%]">
                <div className="h-[76.5%] md:h-[76%] border-[1px] border-dashed border-[#DB2228] absolute top-3 md:left-[17.7px]"></div>
              </div>
              <div className="w-[94%]">
                <div className="workContent">
                  <div className="workContentHeading">
                    <h2 className="heading3">Step 1 </h2>
                    <span className="heading2 text-[#dc332e]">
                      Choose a plan
                    </span>
                  </div>
                  <div className="imageWrapper">
                    <img
                      src={require("../assets/homepage/choose-plan-mobile.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="workContent">
                  <div className="workContentHeading">
                    <h2 className="heading3">Step 2 </h2>
                    <span className="heading2 text-[#dc332e]">
                      Book your service
                    </span>
                  </div>
                  <div className="imageWrapper">
                    <img
                      src={require("../assets/homepage/select-service-mobile.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="workContent">
                  <div className="workContentHeading">
                    <h2 className="heading3">Step 3 </h2>
                    <span className="heading2 text-[#dc332e]">
                      Receive comprehensive care
                    </span>
                  </div>
                  <div className="imageWrapper">
                    <img
                      src={require("../assets/homepage/check-report-mobile.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="workContent">
                  <div className="workContentHeading">
                    <h2 className="heading3">Step 4 </h2>
                    <span className="heading2 text-[#dc332e]">
                      Access digital health records
                    </span>
                  </div>
                  <div className="imageWrapper imageWrapperBorder">
                    <img
                      src={require("../assets/homepage/speak-to-doc-mobile.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowAiqahealthWorks;
