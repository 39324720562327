import React, { useRef, useState } from "react";
import { validateEmail, validateName } from "../../services/constant";
import MobileNumberInput from "../../components/form/MobileNumberInput";
import Label from "../../components/form/Label";
import EmailInput from "../../components/form/EmailInput";
import axios from "axios";
import Swal from "sweetalert2";

const CommonForm = ({ subject, title }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: subject,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [consent, setConsent] = useState(false);
  const checkboxRef = useRef(null);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "consent") return;

    if (name === "name" && !validateName(value)) {
      setError((prev) => ({ ...prev, name: "Invalid name" }));
    } else if (name === "email" && !validateEmail(value)) {
      setError((prev) => ({ ...prev, email: "Invalid email" }));
    } else {
      setError((prev) => ({ ...prev, [name]: "" }));
    }

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormValidation = () => {
    return (
      state.name.length >= 3 &&
      validateName(state.name) &&
      validateEmail(state.email) &&
      state.mobile.length >= 10 &&
      consent
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (handleFormValidation()) {
      axios
        .post("https://aiqahealth.doctoroncall.org/api/lead", state)
        .then((response) => {
          if (response?.status === 200) {
            Swal.fire({
              title: "",
              text: "Request submitted successfully",
              icon: "success",
              confirmButtonColor: "#db2228",
            });
            setState({ name: "", email: "", mobile: "", subject: subject });
            setConsent(false);
            setError({});
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "An error occurred, please try again later.",
            icon: "error",
            confirmButtonColor: "#db2228",
          });
          setError(error?.response?.data?.errors || {});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onConsentHandler = () => {
    setConsent((prev) => !prev);
  };

  const toggleCheckbox = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checkboxRef.current.checked;
      setConsent(checkboxRef.current.checked);
    }
  };

  return (
    <div className="mt-10">
      <form
        onChange={onChangeHandler}
        onSubmit={handleSubmit}
        className="mx-auto mt-4 grid w-full gap-3 md:gap-5 grid-cols-1"
      >
        <div className="mb-2 flex flex-col gap-2">
          <Label label={"Full name"} required={true} />
          <input
            name="name"
            value={state.name}
            onChange={onChangeHandler}
            placeholder="Enter your name"
            className="bg-white outline-none h-12 rounded-xl p-2"
            maxLength={32}
          />
          {error.name && (
            <span className="text-[12px] text-primary-500 pl-3">
              {error.name}
            </span>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="mb-2">
            <MobileNumberInput
              name="mobile"
              value={state.mobile}
              onChange={onChangeHandler}
              required
              label="Phone number"
              placeholder="Enter your phone number"
            />
          </div>
          <div className="mb-2">
            <EmailInput
              name="email"
              value={state.email}
              onChange={onChangeHandler}
              required
              label={"Email"}
              placeholder="Enter your email"
            />
          </div>
        </div>
        <p className="md:pl-2 text-[#333333] para flex items-center md:items-start gap-2">
          <input
            ref={checkboxRef}
            checked={consent}
            onChange={onConsentHandler}
            type="checkbox"
            name="consent"
            className="custom-checkbox h-5 w-5"
          />{" "}
          <span
            onClick={toggleCheckbox}
            className="text-left md:text-justify para cursor-pointer text-[#666666] font-para-sm"
          >
            I agree for aiqa health to contact me.
          </span>
        </p>
        <div className="text-center mt-6 md:mt-10">
          <button
            type="submit"
            disabled={!handleFormValidation() || loading}
            className="my-button h-[40px] px-[30px]"
          >
            {loading ? "Loading..." : title}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommonForm;
