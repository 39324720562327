import React from "react";
import PlanCard from "./common/PlanCard";

const OurPlans = () => {
  return (
    <div id="our-plan">
      <h1 className="heading1 text-center">Our plans </h1>
      <div className="mt-6 md:mt-10">
        <PlanCard />
      </div>
    </div>
  );
};

export default OurPlans;
