import React from "react";

const WhyAiqaPlan = () => {
  const data = [
    {
      title: "Comprehensive Coverage",
      img: require("../assets/homepage/Offerings.png"),
      description:
        "With 20+ offerings, from nutrition to hospitalization, every aspect of your health is well protected.",
    },
    {
      title: "24/7 Availability",
      img: require("../assets/homepage/Availability.png"),
      description:
        "Access round-the-clock healthcare anytime, anywhere through our user-friendly platform, via App or phone.",
    },
    {
      title: "Expert care and value",
      img: require("../assets/homepage/ExpertCare.png"),
      description:
        "Access top services from 20+ providers in one app, all at budget-friendly prices.",
    },
    {
      title: "Flexible plans",
      img: require("../assets/homepage/FlexiblePlans.png"),
      description:
        "Custom-tailored to perfectly fit your unique health and lifestyle needs.",
    },
  ];
  return (
    <>
      <div className="hidden md:block">
        <h1 className="heading1 text-center mb-10">Why choose aiqa plans?</h1>
        <div className="flex gap-4 items-center justify-center flex-wrap">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="bg-white rounded-xl flex flex-col justify-center items-center py-10 px-5 text-center w-full md:w-[48%] lg:w-[300px] gap-6"
              >
                <img alt="" src={item.img} className="h-20" />
                <div className="text-center flex gap-4 flex-col min-h-[126px]">
                  <h1 className="heading3 lg:h-14">{item.title}</h1>
                  <p className="para leading-6 lg:h-24">{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="md:hidden">
        <h1 className="heading1 text-center mb-6">Why choose aiqa plans?</h1>
        <div className="grid grid-cols-2 gap-4 justify-center">
          {data.map((item, index) => {
            let orderClass = "";
            if (index === 1) {
              orderClass = "order-3";
            } else if (index === 3) {
              orderClass = "order-4";
            } else if (index === 2) {
              orderClass = "order-2";
            }
            return (
              <div
                key={index}
                className={`bg-white rounded-xl flex flex-col justify-center items-center p-3 text-center ${orderClass} md:order-none`}
              >
                <img alt="" src={item.img} className="h-[60px]" />
                <div className="h-full text-center mt-6">
                  <h1 className="heading3">{item.title}</h1>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WhyAiqaPlan;
