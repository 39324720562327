import React from "react";
import HealthCareWorld from "../assets/homepage/health-care-wroup.png";
import EconimistGroup from "../assets/homepage/the-econimist-group.png";
import FinancialTimes from "../assets/homepage/financial-times.png";
import BusinessStandard from "../assets/homepage/business-standard.png";

const InNews = () => {
  const data = [
    {
      img: HealthCareWorld,
      url: "https://bwhealthcareworld.com/article/honouring-healthcare-world%E2%80%99s-40-under-40-420395",
    },
    {
      img: EconimistGroup,
      url: "https://economictimes.indiatimes.com/tech/startups/doctor-on-call-aims-for-10-lakh-subscribers-by-2021-end/articleshow/79704360.cms?from=mdr",
    },
    {
      img: FinancialTimes,
      url: "https://economictimes.indiatimes.com/tech/startups/doctor-on-call-aims-for-10-lakh-subscribers-by-2021-end/articleshow/79704360.cms?from=mdr",
    },
    {
      img: BusinessStandard,
      url: "https://indianexpress.com/article/cities/ahmedabad/smriti-irani-gives-over-rs-7-cr-boost-to-maghrol-village/",
    },
  ];

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="flex justify-center items-center flex-col md:p-10 card-wrapper bgc-none">
          <p className="heading1 mb-6 md:mb-10">In news </p>
          <div
            className="flex flex-wrap justify-center overflow-hidden"
            style={{
              border: "1px solid #0000001F",
              borderRadius: "40px",
              overflow: "hidden",
            }}
          >
            {data.map((item, index) => (
              <div key={index} className="w-1/4">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <img key={index} src={item.img} className="" alt="" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InNews;
