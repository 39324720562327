import React from "react";
import founderImg from "../../assets/about/founderImage.png";

const FoundersMessage = () => {
  return (
    <div className="founder-message-container flex flex-col md:flex-row justify-center items-center text-center md:text-left gap-10 p-5 md:p-10">
      <img alt="" src={founderImg} className="h-[340px] md:h-[420px]" />
      <div>
        <p className="heading1 leading-[33.6px] md:leading-[55.6px] lg:leading-[75px] mb-2">
          Founder's message
        </p>
        <p className="para leading-6">
          <span style={{ color: "#db2228" }}>“ </span>
          Our goal is simple yet bold: make comprehensive health & insurance
          benefits accessible and affordable for every Indian, no matter where
          they live or what they earn. We saw the gaps in the system, people
          struggling to get basic care, people pushed into bankruptcy with 1
          hospitalization, and we knew we had to act.
          <br />
          Our platform was born out of a passion to change this reality. With
          the support of over 50 partners and the trust of 80 lakh+ customers,
          we’re leading a movement to make quality health and financial
          protection a right for all.
          <span style={{ color: "#db2228" }}> ”</span>
        </p>
      </div>
    </div>
  );
};

export default FoundersMessage;
