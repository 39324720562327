import React from "react";

const OurPresence = () => {
  const data = [
    { num: "250+", title: "Health centers" },
    { num: "600+", title: "Cities/villages" },
    { num: "500+", title: "Doctors" },
    { num: "5000+", title: "Pincodes" },
  ];
  return (
    <>
      <div className="map-wrapper hidden md:block">
        <div className="flex justify-center flex-col items-center text-center">
          <p className="heading1 mb-2">Our presence </p>
          <p className="para font-[500]">
            Delivering quality healthcare with an extensive network and expert
            medical professionals
          </p>
        </div>
        <div className="w-full h-full grid grid-cols-1 gap-4 place-content-evenly">
          <div className="w-full justify-between hidden md:flex lg:w-[85%] lg:mx-auto">
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">250+</p>
              <p className="para text-[#333333]">Health centers</p>
            </div>
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">600+</p>
              <p className="para text-[#333333]">Cities/villages</p>
            </div>
          </div>
          <div className="w-full justify-between hidden md:flex lg:w-[85%] lg:mx-auto">
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">500+</p>
              <p className="para text-[#333333]">Doctors</p>
            </div>
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">5000+</p>
              <p className="para text-[#333333]">Pincodes</p>
            </div>
          </div>
        </div>
        <div className="flex md:hidden flex-wrap gap-4 md:gap-3 justify-between md:justify-around">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="card p-5 w-[47%] md:w-2/5 text-center"
              >
                <p className="heading2 mb-2">{item.num}</p>
                <p className="para">{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="md:hidden">
        <div className="flex justify-center flex-col items-center text-center">
          <p className="heading1 mb-2">Our presence </p>
          <p className="para font-[500]">
            Delivering quality healthcare with an extensive network and expert
            medical professionals
          </p>
        </div>
        <div className="map-wrapper"></div>
        <div className="w-full h-full grid grid-cols-1 gap-4 place-content-evenly">
          <div className="w-full justify-between hidden md:flex lg:w-[85%] lg:mx-auto">
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">250+</p>
              <p className="para text-[#333333]">Health centers</p>
            </div>
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">600+</p>
              <p className="para text-[#333333]">Cities/villages</p>
            </div>
          </div>
          <div className="w-full justify-between hidden md:flex lg:w-[85%] lg:mx-auto">
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">500+</p>
              <p className="para text-[#333333]">Doctors</p>
            </div>
            <div className="circular-card p-5 min-w-44 text-center">
              <p className="pb-2 heading2 text-[24px]">5000+</p>
              <p className="para text-[#333333]">Pincodes</p>
            </div>
          </div>
        </div>
        <div className="flex md:hidden flex-wrap gap-4 md:gap-3 justify-between md:justify-around">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="card p-5 w-[47%] md:w-2/5 text-center"
              >
                <p className="heading2 mb-2">{item.num}</p>
                <p className="para">{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OurPresence;
