import React from "react";
import CustomCarousel from "./common/CustomCarousel";
import svcl from "../assets/become_reseller/svcl.png";
import moneyLogi from "../assets/become_reseller/money-logi.png";
import indiTrade from "../assets/become_reseller/indi-trade.png";
import hesa from "../assets/become_reseller/hesa.png";
import bangiya from "../assets/become_reseller/bangiya.png";
import servitium from "../assets/become_reseller/servitium.png";
import unity from "../assets/become_reseller/unity.png";
import kadama from "../assets/become_reseller/kadama.png";
import sugmya from "../assets/become_reseller/sugmya.png";
import origo from "../assets/become_reseller/origo.png";
import grameenShakti from "../assets/become_reseller/grameen-shakti.png";

const OurPartner = ({ title }) => {
  const data = [
    svcl,
    moneyLogi,
    indiTrade,
    hesa,
    bangiya,
    servitium,
    unity,
    kadama,
    sugmya,
    origo,
    grameenShakti,
  ];

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className="card text-center p-5 md:p-10 flex justify-center flex-col rounded-[30px]">
      <p className="heading1 mb-6 md:mb-10">{title}</p>
      <div className="w-full flex justify-center">
        <div className="w-full text-center">
          <CustomCarousel settings={settings}>
            {data.map((item, index) => {
              return (
                <div key={index} className="w-full">
                  <div className="flex justify-center md:w-full ">
                    <img
                      className="h-[70px] md:h-20 lg:h-24"
                      alt="img"
                      key={index}
                      src={item}
                    />
                  </div>
                </div>
              );
            })}
          </CustomCarousel>
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
