export const faqData = [
  {
    title: "What is aiqa health?",
    body: "aiqa health is a comprehensive healthcare platform offering a wide range of health and wellness services, including doctor consultations, diagnostic tests, nutritionist consultations, mental wellness services, and more.",
  },
  {
    title: "How does aiqa health ensure the quality of its services?",
    body: "We partner with 20+ trusted providers to deliver top-notch, reliable healthcare and wellness services through aiqa health App, a user-friendly platform.",
  },
  {
    title: "How do I book an appointment through aiqa health?",
    body: "Explore our range of services on aiqa health App, choose your desired service, and book your appointment seamlessly.",
  },
  {
    title: "How do I access mental wellness services?",
    body: "You can access mental wellness services through our platform by booking appointments for therapy and counselling sessions.",
  },
  {
    title: "How do aiqa health centers operate?",
    body: "aiqa health centers offer neighborhood health services, including body vitals checkups, teleconsultations, and free OTC medicines.",
  },
  {
    title: "How do I use the ambulance on call service?",
    body: "You can easily access our ambulance on call service via our app or call centre for safe and swift transport to medical facilities.",
  },
  {
    title: "What happens during health checkup camps?",
    body: "Our periodic health checkup camps offer comprehensive health check-ups and complimentary doctor consultations to promote proactive health management.",
  },
  {
    title: "What is creditLife cover?",
    body: "creditLife cover provides financial security against unforeseen events, ensuring peace of mind for your future.",
  },
  {
    title: "How are digital health records managed?",
    body: "Digital Health Records are securely stored and easily accessible anytime, anywhere, providing organized and convenient access to your health information.",
  },
  {
    title: "Can I get discounts on medicines?",
    body: "Yes, we offer discounts on medicines, along with timely home delivery for added convenience.",
  },
  {
    title: "How can I reach out for support or more information?",
    body: "You can contact our customer support team at +91 6262 306 306 or through aiqa health App, for any assistance or additional information.",
  },
  {
    title: "Can I consult with a doctor over the phone or video call?",
    body: "Yes, aiqa health provides both audio and video doctor teleconsultations for your convenience.",
  },
  {
    title: "Are there any additional charges for specialist consultations?",
    body: "Specialist consultations are included in certain plans. Check your plan details to understand the coverage.",
  },
  {
    title: "Which hospitals are covered under aiqa health care?",
    body: "aiqa health does not restrict users to a network of hospitals. You can choose any hospital registered with the Government of India.",
  },
  {
    title:
      "Can I cover my family under one policy if we live in different cities?",
    body: "Yes, you can cover your entire family under one policy. aiqa health is valid across India, regardless of your location.",
  },
  {
    title: "What if I need to cancel my appointment?",
    body: "You can easily cancel or reschedule your appointment through the aiqa health app.",
  },
  {
    title: "How does aiqa health ensure data privacy and security?",
    body: "Aiqa health uses advanced encryption and security protocols to ensure that your personal and health data is protected at all times.",
  },
  {
    title: "Can I access aiqa health services while traveling?",
    body: "Yes, you can access aiqa health services from anywhere in India, even while traveling.",
  },
  {
    title: "What types of diagnostic tests can I book through aiqa health?",
    body: "You can book a wide range of diagnostic tests, including blood tests, urine tests, and specialized diagnostic tests.",
  },
  {
    title: "Is there a limit on the number of doctor consultations I can have?",
    body: "No, there is no limit. With aiqa health, you get unlimited doctor consultations as per your plan.",
  },
  {
    title: "How do I access my digital health records?",
    body: "Your digital health records can be accessed anytime through the aiqa health app.",
  },
  {
    title:
      "What support is available if I face issues with the aiqa health app?",
    body: "Our customer support team is available 24/7 at +91 6262 306 306 to assist you with any issues or questions you may have about the aiqa health app.",
  },
];

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const validateName = (name) => {
  const nameRegex = /^[a-zA-Zà-ÿÀ-ÿ' -]+$/;
  return nameRegex.test(name);
};

export function validateMobileNumber(number) {
  const mobileNumberRegex = /^\d{10}$/; // Exactly 10 digits
  return mobileNumberRegex.test(number);
}
