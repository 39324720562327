import React, { useEffect, useState } from "react";
import { faqData } from "../services/constant";
import { useNavigate } from "react-router-dom";

const AccordionItem = ({ title, content, isOpen, onClick, isLast }) => (
  <div
    style={{ borderBottom: isLast ? "none" : "1px solid rgba(0, 0, 0, 0.12)" }}
  >
    <button
      className="w-full flex justify-between items-center text-left py-4 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
      onClick={onClick}
    >
      <span className="heading3 leading-[25.2px] md:leading-8 w-[94%] md:w-auto">
        {title}
      </span>
      <svg
        className={`w-5 h-5 transition-transform duration-300 ${
          isOpen ? "transform rotate-180" : ""
        }`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
    <div
      className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
        isOpen ? "max-h-screen" : "max-h-0"
      }`}
    >
      <div className="para px-4 pb-4 text-[#4D4D4D]">{content}</div>
    </div>
  </div>
);

const FAQ = ({ type = false }) => {
  const [openIndex, setOpenIndex] = useState(0);
  const navigate = useNavigate();

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const onClickHandler = () => {
    navigate("/faqs");
  };

  useEffect(() => {
    // Detect screen size and open the first accordion item on larger screens only
    const handleResize = () => {
      if (window.innerWidth >= 991) {
        setOpenIndex(0);
      } else {
        setOpenIndex(null);
      }
    };

    handleResize(); // Set the initial state
    window.addEventListener("resize", handleResize); // Update state on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <div
      className={`${
        !type && "home-wrapper"
      } flex flex-col items-center md:px-5 lg:px-0`}
    >
      <div
        className={`w-full md:max-w-[1248px] ${
          !type && "my-[48px] md:my-[120px]"
        }`}
      >
        <div className="card-wrapper px-0 py-6 md:p-6 lg:py-10 lg:px-[100px]">
          <p className="heading1 mb-6 md:mb-10 text-center">FAQs</p>
          {faqData?.map((item, index) =>
            type ? (
              index < 6 ? (
                <AccordionItem
                  key={index}
                  title={item.title}
                  content={item.body}
                  isOpen={openIndex === index}
                  onClick={() => handleClick(index)}
                  isLast={index === Math.min(faqData.length - 1, 5)}
                />
              ) : (
                ""
              )
            ) : (
              <AccordionItem
                key={index}
                title={item.title}
                content={item.body}
                isOpen={openIndex === index}
                onClick={() => handleClick(index)}
                isLast={index === faqData.length - 1}
              />
            )
          )}
          <div className="mt-6 md:mt-10 justify-center flex">
            {type === "home" ? (
              <button
                onClick={onClickHandler}
                className="my-button z-10 h-[50px] w-[140px]"
              >
                View All
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
