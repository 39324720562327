import Layout from "./layout/Layout";
import Home from "./pages/Home";
import "./app.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/AboutUs";
import BecomeReseller from "./pages/BecomeReseller";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HowToReach from "./pages/HowToReach";
import TermsAndConditions from "./pages/TermsAndConditions";
import Grievances from "./pages/Grievances";
import AiqaTransform from "./pages/AiqaTransform";
import DigitalHealthSubscription from "./pages/DigitalHealthSubscription";
import InvestorPortal from "./pages/InvestorPortal";
import KnowMorePlan from "./pages/KnowMorePlan";
import FAQ from "./components/FAQ";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.compat.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const layoutWrapper = ({ ...children }) => {
    return <Layout>{children}</Layout>;
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: layoutWrapper(<Home />),
    },
    {
      path: "/our-plan/:plan",
      element: layoutWrapper(<KnowMorePlan />),
    },
    {
      path: "/about-us",
      element: layoutWrapper(<AboutUs />),
    },
    {
      path: "*",
      element: layoutWrapper(<PageNotFound />),
    },
    {
      path: "/become-reseller",
      element: layoutWrapper(<BecomeReseller />),
    },
    {
      path: "/contact-us",
      element: layoutWrapper(<ContactUs />),
    },
    {
      path: "/privacy-policy",
      element: layoutWrapper(<PrivacyPolicy />),
    },
    {
      path: "/how-to-reach",
      element: layoutWrapper(<HowToReach />),
    },
    {
      path: "/terms-and-conditions",
      element: layoutWrapper(<TermsAndConditions />),
    },
    {
      path: "/grievances",
      element: layoutWrapper(<Grievances />),
    },
    {
      path: "/aiqa-transform",
      element: layoutWrapper(<AiqaTransform />),
    },
    {
      path: "/digital-health-subscription",
      element: layoutWrapper(<DigitalHealthSubscription />),
    },
    {
      path: "/investor-portal",
      element: layoutWrapper(<InvestorPortal />),
    },
    {
      path: "/faqs",
      element: layoutWrapper(<FAQ />),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
