import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="home-wrapper flex flex-col justify-center items-center text-justify px-5 lg:px-0">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px]">
        <div>
          <h1 className="heading1">Our Privacy Policy</h1>
        </div>
        <div className="mt-10">
          <h2 className="heading2 mb-2">Privacy policy</h2>
          <p className="para">
            This privacy policy sets out how aiqahealth uses and protects any
            information that you provide to aiqahealth when you use or visit
            this website. the domain name [aiqahealth .com] (the ‘website’) is
            owned and managed by aiqahealthtor on call private limited, having
            office at 5th floor, sas tower, sector 38, gurugram haryana 122001.
            the term aiqahealth, ‘company’, ‘us’, ‘we’ or ‘our’ refers to
            aiqahealth. the term ‘user’, ‘you’ or ‘your’ refers to a patient,
            his/her representatives or affiliates; any person in need of
            healthcare services either for himself or his affiliates, and/or any
            user or viewer or visitor of this website. the term ‘third party’
            rthis privacy policy constitutes a complete legally binding contract
            between aiqahealth and the user. the terms of privacy policy will be
            applicable and effective upon your usage of the website/ acceptance
            of the same and shall govern the relationship between the user and
            aiqahealth for the use of this website. aiqahealth is committed to
            confidentiality and privacy of all its users. all identifiable data
            collected by aiqahealth shall be dealt with in accordance with this
            policy. aiqahealth may change this policy from time to time. user is
            advised to check this page from time to time to ensure that you are
            informed of the changes or additions made thereunder. your continued
            use of this website and availing of services of aiqahealth under
            this website implies that you have read, understood and agreed to
            the terms and conditions as set forth in this policy and implies
            your acceptance to any amendments or modifications made to this
            privacy policy. your continued use of the website implies your
            consent to our collection and use of the information.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="heading2 mb-2">What information do we collect?</h2>
          <p className="para">
            <p>
              during the use of this website by you we may collect the following
              categories of information:
            </p>
            <ul className="mt-2 list-disc pl-7 para">
              <li> name. </li>
              <li>
                contact information including email id, phone number and postal
                address.
              </li>
              <li>demographic information such as gender and age.</li>
              <li>
                payment information (only as required for the purposes for
                availing services of aiqahealth by a user).
              </li>
              <li>medical history and other physiological information.</li>
              <li>
                information in relation to medical insurance or any other
                analogous healthcare program.
              </li>
              <li>
                non-identifiable information collected by automated cookies to
                visitors of the website.
              </li>
              <li>
                any other information considered necessary in the sole
                discretion of aiqahealth for the performance of services agreed
                to be procured by the user from aiqahealth
              </li>
            </ul>
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">What we use your information for?</h2>
          <p className="para">
            <p>
              aiqahealth may use your information for the following categories
              of uses:
            </p>
            <ul className="mt-2 list-disc pl-7 para">
              <li>
                provision of healthcare services including telemedicine and
                medical & health consolation, referral to external experts for
                further medical evaluation, delivery of medicines, and other
                lifestyle and healthcare related services.
              </li>
              <li>
                service improvement: your information may be used by aiqahealth
                to improve the standard of its services including by way of
                innovating new products and customization in your interests.
              </li>
              <li>
                feedback & preferences: we may also use your information to
                contact you for feedback and market research purposes. we may
                contact you by email, phone, fax, mail or any other means of
                electronic or digital communication. we may conduct various data
                analytics process using your information to study and examine
                user preferences in order to improve aiqahealth offerings.
              </li>
              <li>
                payment: your financial information including credit card, debit
                card, bank account or medical insurance related information may
                be used for completing payment transactions between aiqahealth
                and you.
              </li>
              <li>
                marketing new offerings: we may periodically send promotional
                emails about new products, special offers or other information
                which we think you may find interesting using the email address
                or mobile no. which you may have provided.
              </li>
              <li>
                research & development: we may use your information for research
                and development in the field of medicine and to improve the
                services already offered and to be offered by aiqahealth,
                including with such other entities conducting research on terms
                materially similar to this policy.
              </li>
            </ul>
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">Security of your information</h2>
          <p>
            aiqahealth has implemented security protocols and standards to
            safeguard your information as required by the applicable laws of the
            republic of india including but not limited to the applicable
            requirements under the telemedicine guidelines issued by the board
            of governors of the medical council of india dated 25.03.2020. we
            have placed appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information and other data stored on the website etc., amongst
            others.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">Your obligation</h2>
          <p className="para">
            You are required to provide true and accurate information to
            aiqahealth for the purposes of availing our services and in your own
            best interests. you are solely responsible for any loss or injury
            occurring due to the provision of false, inaccurate, ambiguous or
            misleading information provided by you. aiqahealth reserves the
            right to deny services to you in case you fail to provide
            information considered by aiqahealth, in its sole discretion, to be
            necessary for the provision of any such services in accordance with
            its quality standards.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">Third-party sharing</h2>
          <p className="para">
            we do not share or otherwise disclose your identifiable or sensitive
            information collected during your visit to aiqahealth website or
            during the provision of services offered by the website for any
            consideration. we may share non-identifiable and non-sensitive
            information on anonymized bases with our partners and/or affiliates
            pursuant to agreements in writing containing the same or similar
            safeguards as provided under this policy. aiqahealth, however, shall
            not be responsible for any breach of the aforesaid requirement any
            such partner and/or affiliate. we share your identifiable
            information with treatment centers, hospitals, medical
            practitioners, healthcare providers, diagnostics centers and other
            third parties limited to the purpose of providing services offered
            by aiqahealth either through this website or otherwise in accordance
            with the highest standards of quality of service.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">Cookies policy</h2>
          <p className="para">
            <p>
              aiqahealth uses cookies to improve the experience for visitors to
              our websites, including websites that can be accessed through our
              mobile applications, and has published this cookie policy. it
              explains what cookies are and how we use them on our websites. if
              you don’t want to receive cookies while browsing our website, you
              can modify your browser and/or mobile device settings so that it
              notifies you when receiving cookies or you can choose to restrict
              or block cookies.
            </p>
            <p>
              The data generated through cookies on aiqahealth websites can be
              used for various purposes including the following:
            </p>
            <ul className="mt-2 list-disc pl-7 para">
              <li>
                strictly necessary: these cookies are essential in order to
                enable you to move around the website and use its features.
              </li>
              <li>
                performance: these cookies collect information about and in the
                pattern visitors use a website, for instance which pages
                visitors go to most often and if they receive error messages.
              </li>
              <li>
                they are used to improve how a website works in future versions.
              </li>
              <li>
                functionality: these cookies allow the website to remember
                choices you make such as username, language or region you are in
                and provide a more enhanced personal experience.
              </li>
              <li>
                advertising: these cookies are used to deliver content more
                relevant to you and of your interests. we don’t permit any third
                parties to advertise on our website but we cannot control
                advertisers that are allowed by internet service providers to
                advertise whilst you are browsing on the internet. you can learn
                more about how to manage your cookies by visiting the help
                function of your browser, the settings of your mobile device or
                you can visit www.about cookies.org which provides detailed
                information on managing cookies on popular browsers. please be
                aware that parts of our websites may not function correctly if
                you disable cookies.
              </li>
            </ul>
          </p>
        </div>
        <div className="mt-10">
          <h2 className="mb-2 heading2">Contact us</h2>
          <p className="para">
            if you have any questions about this policy or any grievance in this
            regard, please contact us at care@aiqahealth.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
