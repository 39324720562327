import React from "react";
import OurJourney from "../components/about_us/OurJourney";
import OurImpact from "../components/about_us/OurImpact";
import OurValues from "../components/about_us/OurValues";
import FoundersMessage from "../components/about_us/FoundersMessage";
import OurTeam from "../components/about_us/OurTeam";
import HowToApply from "../components/about_us/HowToApply";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="w-full flex flex-col items-center">
        <OurJourney />
      </div>
      <div className="flex flex-col items-center px-5 lg:px-0">
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <OurImpact />
        </div>
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px]">
          <OurValues />
        </div>
      </div>
      <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] flex justify-center mx-auto">
        <FoundersMessage />
      </div>
      <div className="flex flex-col items-center px-5 lg:px-0">
        <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] flex justify-center">
          <OurTeam />
        </div>
      </div>
      <div className="w-full md:max-w-[1248px] mt-[48px] md:mt-[120px] flex justify-center mx-auto">
        <HowToApply />
      </div>
    </div>
  );
};

export default AboutUs;
