import React, { useEffect, useRef, useState } from "react";
import CheckAllIcon from "../assets/common/check-all.svg";
import Slider from "react-slick";
import ambulanceOnCall from "../assets/homepage/ambulanceOnCall.png";
import digitalHealthRecord from "../assets/homepage/digitalHealthRecord.png";
import fitness from "../assets/homepage/fitness.png";
import onlineDoctorConsultation from "../assets/homepage/onlineDoctorConsultation.png";
import labTest from "../assets/homepage/labTest.png";
import mentalWellness from "../assets/homepage/mentalWellness.png";
import nutriConsult from "../assets/homepage/nutriConsult.png";
import medicineDelivery from "../assets/homepage/medicineDelivery.png";

const WhatWeOffer = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const setting = {
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  };

  const data = [
    {
      buttonTitle: "Diagnostic test",
      item: {
        title: "Trustworthy and convenient testing solutions",
        list: [
          "Convenient testing from the comfort of your home",
          "Quick and accurate results for informed health decisions",
          "Professional and trained phlebotomists for safe sample collection",
        ],
        img: labTest,
      },
    },
    {
      buttonTitle: "Digital health records",
      item: {
        title: "Streamlined access to your health information",
        list: [
          "Conveniently view and manage your medical records",
          "24/7 availability to access your health information anytime, anywhere",
          "Safe and confidential storage of your health data",
        ],
        img: digitalHealthRecord,
      },
    },
    {
      buttonTitle: "Fitness subscription",
      item: {
        title: "Unlimited fitness opportunities for a healthier lifestyle",
        list: [
          "Access to fitness centres across the country",
          "A wide network of 1200+ fitness locations to choose from",
          "Over 10 diverse workout forms including yoga, pilates, cardio, strength training, and more",
        ],
        img: fitness,
      },
    },
    {
      buttonTitle: "Ambulance on call",
      item: {
        title: "Reliable transport for scheduled hospital visits",
        list: [
          "Professional staff and equipped ambulances for your safety and comfort",
          "24/7 availability for round the clock access to services",
          "Easily schedule an ambulance through our call centre or mobile App",
        ],
        img: ambulanceOnCall,
      },
    },
    {
      buttonTitle: "Doctor consultation",
      item: {
        title: "Get professional advice and care for your health concerns",
        list: [
          "Ongoing medical concerns or new symptoms",
          "General health and wellness advice",
          "Preventive care and specialist opinions",
        ],
        img: onlineDoctorConsultation,
      },
    },
    {
      buttonTitle: "Mental wellness",
      item: {
        title: "Comprehensive support for your mental health",
        list: [
          "Multilingual support of 14 languages to cater to diverse needs",
          "Instant chat and video sessions for convenient and timely assistance",
          "Access to professionals across 6 different categories, including counselling, therapy, and more",
        ],
        img: mentalWellness,
      },
    },
    {
      buttonTitle: "Nutritionist consultation",
      item: {
        title: "Tailored nutrition guidance for a healthier you",
        list: [
          "Personalized meal plans based on your unique needs and goals",
          "Access to experienced and certified nutritionists",
          "Bi-weekly follow-ups to track progress and adjust plans",
        ],
        img: nutriConsult,
      },
    },
    {
      buttonTitle: "Medicine delivery",
      item: {
        title: "Convenient access to affordable medications",
        list: [
          "Exclusive discounts on medicines",
          "Additional savings with pharmacy vouchers",
          "Prompt and secure home delivery",
        ],
        img: medicineDelivery,
      },
    },
  ];

  const slideChangeHandler = (current, next) => {
    setCurrentSlide(next);
  };

  const onClickHandler = (index) => {
    if (nav1) {
      nav1.slickGoTo(index);
    }
  };

  return (
    <div className="flex justify-center flex-col items-center">
      <p className="heading1 mb-6 md:mb-10">What all we offer</p>

      <div className="w-full mb-6 md:mb-10">
        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2 = slider)}
          slidesToShow={4.05}
          swipeToSlide={true}
          arrows={true}
          speed={1500}
          autoplay={true}
          infinite={true}
          {...setting}
        >
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`cursor-pointer ml-0 lg:ml-5`}
                  onClick={() => onClickHandler(index)}
                >
                  <p
                    className={`${
                      currentSlide === index
                        ? "bg-red text-white font-[600]"
                        : "bg-white text-[#252525]"
                    } h-11 text-center flex items-center justify-center para custom-border p-3 mx-2`}
                  >
                    {item?.buttonTitle}
                  </p>
                </div>
              );
            })}
        </Slider>
      </div>
      <div className="w-full">
        <Slider
          asNavFor={nav2}
          ref={(slider) => (sliderRef1 = slider)}
          slidesToShow={1}
          beforeChange={slideChangeHandler}
          arrows={false}
          speed={1500}
          pauseOnHover={false}
          dots={true}
          infinite={true}
        >
          {data?.map((item, index) => {
            return (
              <div key={index} className="w-full">
                <div
                  className="flex flex-col-reverse lg:flex-row items-center card-wrapper p-5 md:p-10 min-h-[440px] gap-6 md:gap-[50px] mx-3"
                  key={index}
                >
                  <div className="flex flex-col justify-start lg:justify-center lg:w-[50%] w-full min-h-[280px] md:min-h-[200px] lg:min-h-[360px]">
                    <p className="heading2 mb-2 relative">
                      {item?.buttonTitle}
                    </p>
                    <p className="para text-[#4D4D4D] mb-3 md:mb-6">
                      {item.item?.title}
                    </p>
                    <ul className="pt-20flex flex-col gap-1.5">
                      {item?.item?.list?.map((elem, idx) => {
                        return (
                          <li key={idx} className="flex gap-2 items-start">
                            <img
                              src={CheckAllIcon}
                              alt=""
                              height="18px"
                              width="18px"
                            />{" "}
                            <span className="text-[#000000] para leading-6">
                              {elem}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="flex justify-center lg:w-[50%] w-full">
                    <div className="overflow-hidden">
                      <img
                        src={item.item?.img}
                        alt=""
                        className="what-we-all-images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default WhatWeOffer;
